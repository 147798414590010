import * as React from "react";
import { List, SimpleList } from 'react-admin';
import { Grid, Hidden, makeStyles } from "@material-ui/core";

import { noclegFormat } from "../../obliczenia/nocleg";
import { OsobaDane } from "../osoby/OsobaDane";

// const postRowStyle = (record, index) => ({
//     backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
// });

const useStyles = makeStyles((theme) => ({
    root: {
            marginTop: 16
    },
}));


const NoclegOsoba = (props) => {

    let dane = noclegFormat(props);

    return (
        <Grid
            container item
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
        >
            <Hidden mdDown><Grid item lg={1}>
                <small>{dane.id}. </small>
            </Grid></Hidden>
            <Grid item lg={11}>
                <span>
                    <Hidden lgUp><small>({dane.id})</small> </Hidden>
                    {dane.dataod_label} - {dane.datado_label}, <Hidden lgUp><br /></Hidden>
                    <OsobaDane o={dane.osoba} typ="nocleg" />
                </span><br/>

                <span>kat. <b>{dane.kategoria_label}</b>, noclegów: <b>{dane.nocy}</b>, opłata: <b>{dane.kwota} zł</b> </span>

                { dane.nocy > 1 && <i> - opłata klimatyczna: {dane.zw ? <b>niepobrana</b> : <b>{dane.oklimat} zł</b>}</i> }
                <br />
                { dane.info && dane.info.hasOwnProperty('uwagi') && <span><i>Uwagi: {dane.info.uwagi}</i></span> }
            </Grid>
        </Grid>
    );
};


export const NoclegiLista = (props) => {
    const classes = useStyles();
    //console.log(props);
    return (
    <List 
        {...props} 
        title={props.options.label}
        actions={false} 
        sort={{ field: 'od', order: 'DESC' }} 
        className={classes.root}
    >
        <SimpleList
            primaryText={NoclegOsoba}
            // secondaryText={NoclegUwagi}
            // tertiaryText={record => new Date(record.dodane).toLocaleDateString()}
            // linkType={record => record.canEdit ? "edit" : "show"}
            linkType={"edit"}
            // rowStyle={postRowStyle}
        />
    </List>
    );
}

