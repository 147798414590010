import dayjs from 'dayjs'
// import { db } from '../security/dataProviderDB';
// import { clean } from 'fast-clean';

// pobranie usera z bazy i zwrócenie obiektu
// export async function getJuser(id) {
        
//         let juser = {};
//         await db.jusers.get(id).then(u=>{
//                 juser = u;
//                 let o = u.name.split(" ");
//                 juser.imie = o[0];
//                 juser.nazwisko = o[1];
//                 // juser.label = u.name+", email: "+u.email+", tel.:"+u.telefon;
//         });

//         // console.log("juser",juser);

//         return juser;
// };

// formatowanie obiektu do wyświetlenia na ekranie
// export const jusersFormat = (props) => {
        
//         let jusers = props;

//         if (props.info && props.info.length===0) props.info = jusers.info; // jeśli info jest pustą tablicą to nadpisz domyślnymi wartościami
//         // props = clean(props, {nullCleaner: true}); // wyczyszczenie starego formatu i pustych danych
//         Object.entries(props).forEach(([k,v]) => { jusers[k] = v }); // przepisanie właściwości z propsa na jusers

//         jusers.dataod_label = dayjs(props.od).format('YYYY-MM-DD');
//         jusers.datado_label = dayjs(props.do).format('YYYY-MM-DD');
//         jusers.nocy = dayjs(jusers.datado_label).diff(dayjs(jusers.dataod_label), 'days');

//         jusers.kwota = 0;

//         return jusers;
// };

// transkrypcja rekordu z API na dane do zapisania w IDB
export const jusersBaza = (props) => {

        // let jusers = jusersInit;
        let jusers = {};

        jusers.id               = props.id;
        jusers.name             = props.name;
        jusers.username         = props.username;
        jusers.email            = props.email;
        jusers.roles            = props.roles;
        jusers.adres            = props.adres;
        jusers.telefon          = props.telefon;
        jusers.pesel            = props.pesel;
        jusers.d                = dayjs(props.dodane).format();
        jusers.a                = dayjs(props.aktualizacja).format();

        return jusers;
};


// zdefiniowanie co będzie zapisane w IDB:
// export const jusersZapis = (props) => {

//         // console.log("zapis - początek",props);

//         // let jusers = jusersInit;
//         let jusers = jusersInit();

//         if (props.id>0) jusers.id = props.id;
//         jusers.dzien     = dayjs(props.dzien).format('YYYY-MM-DD');
//         jusers.gadzet    = props.gadzet;
//         jusers.liczba    = props.liczba;
//         jusers.kwota     = props.kwota;
//         jusers.info      = props.info;
//         jusers.d         = dayjs(props.d).format();
//         jusers.a         = dayjs().format();

//         // console.log("zapis - koniec",jusers); 

//         return clean(jusers); // , {nullCleaner: true}
// };

// // format informacji w rozwiązywaniu konfliktów:
// export const jusersDiff = (props) => {

//         let jusers = {};

//         // if (props.id>0) nocleg.id = props.id;
//         jusers.dzien          = dayjs(props.dzien).format('YYYY-MM-DD');
//         jusers.gadzet         = props.gadzet;
//         jusers.liczba         = props.liczba;
//         jusers.kwota          = props.kwota;
//         jusers.info           = props.info;
//         jusers.dodane         = dayjs(props.d).format('YYYY-MM-DD HH:mm:ss');
//         jusers.aktualizacja   = dayjs(props.a).format('YYYY-MM-DD HH:mm:ss');

//         return clean(jusers); // , {nullCleaner: true}
// };

// // obiekt do wysłania do API:
// export const jusersAPI = (props) => {

//         let jusers = {};

//         jusers.id             = props.id;
//         jusers.data           = dayjs(props.dzien).format('YYYY-MM-DD');
//         jusers.gadzet         = props.gadzet;
//         jusers.liczba         = props.liczba;
//         jusers.kwota          = props.kwota;
//         jusers.info           = JSON.stringify(props.info);
//         jusers.dodane         = dayjs(props.d).format();
//         jusers.aktualizacja   = dayjs(props.a).format();

//         // właściwości może być mniej lub więcej - ważne by na końcu JSON-a nie było przecinka ;-), bo generuje błąd...
//         return clean(jusers); 
// };

// // inicjalizacja obiektu
// export function jusersInit() {
//         let jusers = {
//                 dzien: dayjs().format('YYYY-MM-DD'),
//                 gadzet: null,
//                 liczba: null,
//                 kwota: null,
//                 info: {},
//                 d: dayjs().format(),
//                 a: dayjs().format()
//         };
//         return jusers;
// }



// // Walidacja jusersu:
// export const validatePrzychod = (values) => {
//         const errors = {};
//         if (!values.od) {
//                 errors.od = 'Podaj dzień przyjścia';
//         }
//         //     if (!values.age) {
//         //         // You can return translation keys
//         //         errors.age = 'ra.validation.required';
//         //     } else if (values.age < 18) {
//         //         // Or an object if the translation messages need parameters
//         //         errors.age = {
//         //             message: 'ra.validation.minValue',
//         //             args: { min: 18 }
//         //         };
//         //     }
//         return errors
// };




