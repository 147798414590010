import * as React from 'react';
// import { createElement } from 'react';
import { useSelector } from 'react-redux';
// import { useMediaQuery } from '@material-ui/core';
import { 
        // DashboardMenuItem, 
        MenuItemLink, 
        getResources 
} from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
// import LabelIcon from '@material-ui/icons/Label';
import SynchroInfo from '../komponenty/SynchroInfo';

const Menu = ({ onMenuClick }) => {
//     const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);

    return (
        <div><br/>
            {/* <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} /> */}
            {resources.map(resource => (<React.Fragment>
                { resource.hasList && <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={
                        (resource.options && resource.options.label) ||
                        resource.name
                    }
                    leftIcon={
                        resource.icon ? <resource.icon /> : <DefaultIcon />
                    }
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                /> }
                </React.Fragment>
            ))}
            {/* <MenuItemLink
                to="/custom-route"
                primaryText="Miscellaneous"
                leftIcon={<LabelIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            /> */}
            {/* {isXSmall && logout} */}
            <SynchroInfo mini sidebarIsOpen={open} />
        </div>
    );
};

export default Menu;