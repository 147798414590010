import React from 'react';
import { Link } from 'react-admin';
import { 
        Tooltip, 
        Typography,
        Button,
        Dialog,
        DialogTitle,
        DialogContent,
        DialogActions,
        useMediaQuery,
} from "@material-ui/core";

import HotelOutlinedIcon from '@material-ui/icons/HotelOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { useFormState } from 'react-final-form';
import decodeJwt from 'jwt-decode';
import OdwiedzinyOsobyPolaczone from './OdwiedzinyOsobyPolaczone';
import { db } from "../../security/dataProviderDB";
import LegendPop from '../LegendPop';


export function OdwiedzinyNoclegDialog(props) {
  const { onClose, open } = props;
  const [fresh, setFresh] = React.useState(0);
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('md'));
  const { values } = useFormState();
  const tok = decodeJwt(localStorage.getItem('token'));
  values.zalogowany = tok.roles[0];

  React.useEffect(() => {
    db.jusers.get(values.mod).then(o => {
      values.mod_info = o; // dane ktosia wpisujemy jako część rekordu
    });

    db.jusers.get(values.kto).then(o => {
      let os = o.name.split(" ");
      o.imie = os[0];
      o.nazwisko = os[1];
      o.nocleg = {};
      o.nocleg.osoba = values.odwOsoPol ? values.odwOsoPol : values.odwOsPol;
      o.nocleg.od = values.od;
      o.nocleg.do = values.do;
      o.nocleg.info = { juser: values.kto };
      values.kto_info = o;
      setFresh(fresh=>fresh+1);
    });
  }, [values]);

  const handleClose = () => {
    onClose(fresh);
  };

  return (
    <Dialog 
        onClose={handleClose} 
        aria-labelledby="simple-dialog-title" 
        open={open}
        maxWidth="xl"
        >
      <DialogTitle id="confirmation-dialog-title">
        Tworzenie Noclegu ze Zgłoszenia: 
        { isSmall && <br/> }
        {values.od} - {values.do}
        <LegendPop>
          <p>Dodawanie Noclegu na podstawie Zgłoszenia:</p>
          <ul>
                <li>Gdy dodajesz tu Nocleg - daty przyjścia i wyjścia zostaną przepisane ze Zgłoszenia.</li>
                <li>Trzeba też wskazać Osobę istniejącą w bazie Gości albo dodać nową.</li>
                <li>Gdy dodajesz nową osobę, część informacji zostanie przepisana z danych użytkownika.</li>
                <li>Wszystkie informacje możesz edytować na dowolnym etapie.</li>
                <li>W formularzu edycji Osoby można tworzyć połączenia między Osobami "na stałe".</li>
                <li>Do Noclegu można wskazać jedną osobę, ale Nocleg można duplikować przypisując kolejne Osoby.</li>
                <li>Identyfikatory użytkowników witryny www oznaczone są słowem userID. Goście Chaty oznaczone są słowem osobaID.</li>
          </ul>
        </LegendPop>
      </DialogTitle>
      <DialogContent dividers>

        <OdwiedzinyOsobyPolaczone {...props} />

      </DialogContent>

      <DialogActions>
        {/* <Button autoFocus onClick={handleClose} color="primary">
          Cancel
        </Button> */}
      {values.kto_info && !values.kto_info.nocleg.osoba && <Button
          variant="outlined"
          color="primary"
          component={Link}
          to={{
            pathname: "/osoby/create",
            state: { record: { ...values.kto_info, dubju: [values.kto], id: "" } },
          }}
        >
          Utwórz osobę, by dodać jej nocleg
        </Button> }
        {values.kto_info && values.kto_info.nocleg.osoba && <Button
          variant="outlined"
          color="primary"
          component={Link}
          to={{
            pathname: "/noclegi/create",
            state: { record: { ...values.kto_info.nocleg, id: "" } },
          }}
        >
          Utwórz nocleg wybranej osoby
        </Button> }
      </DialogActions>
    </Dialog>
  );
}

export default function OdwiedzinyNocleg(props) {
  const [openON, setOpenON] = React.useState(false);

  const handleClickOpen = () => {
    setOpenON(true);
  };

  const handleClose = (value) => {
    setOpenON(false);
  };

  return (
    <React.Fragment>
        <Tooltip
        title="Dodaj nocleg utworzony z tej rezerwacji"
        placement="top-end"
        // arrow
        disableFocusListener
        disableTouchListener
      >
        <Typography align="right">
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClickOpen}
          >
            <AddOutlinedIcon /> &nbsp; <HotelOutlinedIcon />
          </Button>
        </Typography>
      </Tooltip>
      <OdwiedzinyNoclegDialog open={openON} onClose={handleClose} {...props} />
    </React.Fragment>
  );
}
