import * as React from "react";
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom';
import { Offline, Online } from "react-detect-offline";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { db } from "../security/dataProviderDB";
import { getIDBmeta, tokenRefresh } from "../obliczenia/komunikacja";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT+'/docs';
// const miesiecy = process.env.REACT_APP_MIESIECY_IMPORT;
///////////////////////////////////////////////////////////////////////////////////////
// PROCEDURA SYNCHRONIZACJI
//
// 1. sprawdzam moment ostatniej synchronizacji zapisany lokalnie - IDB.robocze.checkAPI
// 2. pobieram zakres synchronizacji - APImeta - info co jest do zrobienia - via stan - na ekranie
// 3. przycisk uruchamia synchronizację:
// 4. pobierane są rekordy - paczka po 30 szt.
// 5. dla każdego rekordu następuje sprawdzenie, czy id istnieje w bazie
// 6. jeśli lokalnie nie ma id zdalnego to dodaje rekord
// 7. jeśli dane się nie różnią to nic się nie dzieje
// 8. jeśli istnieje (a dane się różnią) to dane z API nadpisują rekord lokalny a rekord lokalny trafia do roboczych

// do opracowania:
// a) obsługa zbackupowanych wpisów
// b) obsługa wysyłki
//
//////////////////////////////////////////////////////////////////////////////////////



// lista skopiowanych rekordów tymczasowych z pytaniem o decyzję: 
//      a) usunąć? 
//      b) zachować jako kolejne rekordy? 
//      c) nadpisać oryginał
//      d) wskazać dane do nadpisania
// gdy są jakieś decyzje do podjęcia - nie można korzystać z apki.
// gdy brak decyzji do podjęcia wtedy pobieram z IDB wszystkie rekordy nowsze niż lastSaveAPI. A może checkAPI?
// dalsze kroki do przemyślenia


const polling = { // konfiguracja detektora online-offline
        enabled: true,
        url: entrypoint,
        interval: 10000,
        timeout: 1000
};

const SynchroInfo = (props) => {

        // useState ustawia stan całościowo dla jednej zmiennej, dlatego stan trzymany w różnych zmiennych:
        const [apiMeta, setAPImeta] = React.useState(); // meta-informacje do migracji
        const [idbMeta, setIDBmeta] = React.useState(); // meta-informacje do migracji
        const [pobieranie, setPobieranie] = React.useState(false);
        const [info, setInfo] = React.useState("czekaj: synchronizacja w toku");
        const history = useHistory();

        React.useEffect(() => {
                getMeta();
        }, []);

        async function getMeta() {
                await db.roboczy.get({zmienna:"apiMeta"})
                .then( async apiMeta => {
                        setAPImeta(apiMeta.value);
                        await getIDBmeta(apiMeta.value)
                                .then( idbMeta => setIDBmeta(idbMeta) );
                })
                .catch(e => {
                        console.log("error getMeta", e);
                });
        }

        async function handleClick() {
                setPobieranie(true); 
                await tokenRefresh(true).then(e=>{
                        getMeta();
                        // console.log("klik",e);
                        if (e.decyzji>0) {
                                history.location.pathname !== "/roboczy/decyzje" && history.push('/roboczy/decyzje');
                        }
                        setInfo("synchronizacja zakończona")
                        setPobieranie(false); 
                });
        } 

        
        // własciwy komponent:
        return (
          <div
            style={{
              //   position: 'absolute',
              //   top: '15px',
              //   marginLeft: '50%',
              // border: '1px dotted green',
              textAlign: "center",
              zIndex: "1000",
            }}
          >
            {props.mini ? (
              <React.Fragment>
                <br />
                <hr />
                <Typography className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense">
                  {!props.sidebarIsOpen ? (
                    <React.Fragment>
                      zmian:
                      <br />
                      <b>{idbMeta && idbMeta.doWyslania}</b>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      sprawdzane:
                      <br />
                      <b>
                        {apiMeta &&
                          dayjs(apiMeta.checkMoment).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                      </b>
                      <br />
                      <br />
                      do wysłania: <b>{idbMeta && idbMeta.doWyslania}</b> zmian
                      <br />
                      <br />
                      <u>na serwerze aktualizowano:</u>
                      <br />
                      osoby:{" "}
                      {apiMeta && apiMeta.osobies && (
                        <span>
                          {dayjs(apiMeta.osobies.aktualizacja).format(
                            "YYYY-MM-DD"
                          )}
                        </span>
                      )}
                      <br />
                      noclegi:{" "}
                      {apiMeta && apiMeta.noclegis && (
                        <span>
                          {dayjs(apiMeta.noclegis.aktualizacja).format(
                            "YYYY-MM-DD"
                          )}
                        </span>
                      )}
                      <br />
                      wydatki:{" "}
                      {apiMeta && apiMeta.wydatkis && (
                        <span>
                          {dayjs(apiMeta.wydatkis.aktualizacja).format(
                            "YYYY-MM-DD"
                          )}
                        </span>
                      )}
                      <br />
                      przychody:{" "}
                      {apiMeta && apiMeta.przychodies && (
                        <span>
                          {dayjs(apiMeta.przychodies.aktualizacja).format(
                            "YYYY-MM-DD"
                          )}
                        </span>
                      )}
                      <br />
                      odwiedziny:{" "}
                      {apiMeta && apiMeta.odwiedzinies && (
                        <span>
                          {dayjs(apiMeta.odwiedzinies.aktualizacja).format(
                            "YYYY-MM-DD"
                          )}
                        </span>
                      )}
                      <br />
                    </React.Fragment>
                  )}
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                Aplikacja jest
                <Online polling={polling}> on - line </Online>
                <Offline polling={polling}> off - line </Offline>
                <Typography>
                  czas ostatniego sprawdzenia danych: <br />
                  <b>
                    {apiMeta &&
                      dayjs(apiMeta.checkMoment).format("YYYY-MM-DD HH:mm:ss")}
                  </b>
                </Typography>
                <br />
                <Typography>
                  do wysłania: {idbMeta && idbMeta.doWyslania} rekordów
                  lokalnych:
                  <br />
                  osoby:{" "}
                  {idbMeta && idbMeta.osobies && (
                    <span>{idbMeta.osobies.length}</span>
                  )}
                  <br />
                  noclegi:{" "}
                  {idbMeta && idbMeta.noclegis && (
                    <span>{idbMeta.noclegis.length}</span>
                  )}
                  <br />
                  wydatki:{" "}
                  {idbMeta && idbMeta.wydatkis && (
                    <span>{idbMeta.wydatkis.length}</span>
                  )}
                  <br />
                  przychody:{" "}
                  {idbMeta && idbMeta.przychodies && (
                    <span>{idbMeta.przychodies.length}</span>
                  )}
                  <br />
                  odwiedziny:{" "}
                  {idbMeta && idbMeta.odwiedzinies && (
                    <span>{idbMeta.odwiedzinies.length}</span>
                  )}
                  <br />
                  <br />
                </Typography>
                {/* <Offline polling={polling}><Typography>sprawdzenie oczekujących na pobranie niemożliwe</Typography></Offline> */}
                <Typography className="align-left">
                  ostatnie zmiany na serwerze:
                  <br />
                  osoby:{" "}
                  {apiMeta && apiMeta.osobies && (
                    <span>
                      {dayjs(apiMeta.osobies.aktualizacja).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                    </span>
                  )}
                  <br />
                  noclegi:{" "}
                  {apiMeta && apiMeta.noclegis && (
                    <span>
                      {dayjs(apiMeta.noclegis.aktualizacja).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                    </span>
                  )}
                  <br />
                  wydatki:{" "}
                  {apiMeta && apiMeta.wydatkis && (
                    <span>
                      {dayjs(apiMeta.wydatkis.aktualizacja).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                    </span>
                  )}
                  <br />
                  przychody:{" "}
                  {apiMeta && apiMeta.przychodies && (
                    <span>
                      {dayjs(apiMeta.przychodies.aktualizacja).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                    </span>
                  )}
                  <br />
                  odwiedziny:{" "}
                  {apiMeta && apiMeta.odwiedzinies && (
                    <span>
                      {dayjs(apiMeta.odwiedzinies.aktualizacja).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                    </span>
                  )}
                  <br />
                </Typography>
                <br />
                <hr />
                <br />
                <Typography>
                  <small>
                    Synchronizacja uruchamiana jest automatycznie mniej więcej co godzinę,
                    <b> jeśli aplikacja jest on-line</b>.<br />
                    Jeśli pojawią się jakieś konflikty danych (np. gdy
                    zaktualizowane zostały lokalnie informacje istniejące na
                    serwerze - trzeba będzie podjąć decyzję co zrobić: a&#41;
                    zachować dane z serwera? b&#41; zachować dane lokalne?
                    c&#41; dodać wpis lokalny jako nową pozycję. Dopiero po
                    podjęciu wszystkich decyzji będzie możliwe wysłanie
                    lokalnych informacji na serwer.
                    <br />
                    <br />
                    Tutaj można wymusić synchronizację danych:
                  </small>
                </Typography>
                <br />
                <Online polling={polling}>
                  <Button
                    onClick={() => handleClick()}
                    disabled={pobieranie}
                    variant="outlined"
                    color="primary"
                  >
                    Wymuś synchronizację
                  </Button>
                </Online>
                <Offline polling={polling}>
                  <Button disabled variant="outlined" color="primary">
                    Wymuś synchronizację
                  </Button>
                </Offline>
                <br />
                {pobieranie && <CircularProgress />}<br />
                {pobieranie && <Typography>{info}</Typography>}
                <br />
                <br />
                <br />
              </React.Fragment>
            )}
          </div>
        );
};

export default SynchroInfo;
