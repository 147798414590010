import * as React from "react";
import { Toolbar } from 'react-admin';
import { useMediaQuery } from "@material-ui/core";
import { useFormState } from 'react-final-form';

import { NoclegiFormToolbarLarge } from "./NoclegiFormToolbarLarge";
import { NoclegiFormToolbarMobile } from "./NoclegiFormToolbarMobile";

export const NoclegiFormToolbar = props => {

        const isSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
        const { values } = useFormState();

        values.dotkniety = props.pristine;
        if ( props.record.info && props.record.info.juser ) {
          values.dotkniety = false;
        }

        return (
          <Toolbar {...props} >
            {isSmall ? (
                <NoclegiFormToolbarMobile {...props} />
            ) : (
                <NoclegiFormToolbarLarge {...props} />
            )}
          </Toolbar>
        );
};
