import * as React from "react";
import { BooleanInput } from "ra-ui-materialui";
import { useFormState } from 'react-final-form';
import { noclegFormat } from "../../obliczenia/nocleg";

// podsumowanie noclegu wyświetlane w formularzu dodawania noclegu:
export const NoclegDane = (props) => {
    const { values } = useFormState();
    // console.log(values);
    let n = noclegFormat(values);
    return (
        <div style={{ textAlign: 'center' }}>
            Liczba noclegów: { n.nocy>0 ? <span>
                <b>{n.nocy}</b><br/>
                {n.kat && <b>OPŁATA: <big>{n.kwota}</big> zł</b> } <br />
                
                <hr />
                <i><span style={n.zw ? { color: 'grey' } : { color: 'inherit' }}>
                    opłata klimatyczna<br />
                    {n.nocy < 2 && <span>nie obowiązuje ;-&#41;<br /></span>}

                    {n.nocy > 1 && <span>
                        <i>(1,60 zł / dzień)</i><br />
            za {n.nocy - 1} dni = <b>{n.oklimat} zł</b><br />
            <BooleanInput label={ values.zw ? "nie pobrana" : "pobrana" } style={{ display: 'inherit' }} source="zw" defaultValue={true} /><br />
                    </span>}
                </span></i>
            </span> : <span><br/>jeszcze nieustalona</span> }<br />


            {/* <pre>{JSON.stringify(values)}</pre> */}
        </div>
    );
}
