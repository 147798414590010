import * as React from "react";
import { Toolbar } from 'react-admin';
import { useMediaQuery } from "@material-ui/core";
import WydatekFormToolbarLarge from "./WydatekFormToolbarLarge";
import WydatekFormToolbarMobile from "./WydatekFormToolbarMobile";

const WydatekFormToolbar = props => {

        const isSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

        return (
          <Toolbar {...props} >
            {isSmall ? (
                <WydatekFormToolbarMobile {...props} />
            ) : (
                <WydatekFormToolbarLarge {...props} />
            )}
          </Toolbar>
        );
};

export default WydatekFormToolbar;
