import * as React from "react";
import { 
        SimpleForm, 
        Create, 
        Edit, 
        TextInput, 
        NumberInput, 
        SelectInput,
 } from 'react-admin';
// import { useFormState } from 'react-final-form';
import { Typography } from "@material-ui/core";
import DatyField from "../Daty";
import { lista_gadzetow, przychodFormat, przychodInit, przychodZapis } from '../../obliczenia/przychod';
import PrzychodFormToolbar from "./PrzychodFormToolbar";

// const useStyles = makeStyles({
//     osoba: {
//         width: '90%'
//     },
// });

const PrzychodyForm = props => {

        return (
                <SimpleForm {...props}
                        toolbar={<PrzychodFormToolbar />}
                        initialValues={przychodInit}
                        // validate={validatePrzychod}
                        variant="outlined"
                >
                        {/* {console.log("NoclegiForm.props:",props)} */}

                        <Typography className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense">
                                Przychod nr: {props.record.id}
                        </Typography>

                        <DatyField
                                source="dzien"
                                label="Data operacji"
                        />
                        <SelectInput
                                source="gadzet"
                                label="Produkt"
                                choices={lista_gadzetow}
                                optionText="label"
                                optionValue="id"
                                helperText={false}
                        />
                        <NumberInput
                                source="liczba"
                                label="Liczba"
                                helperText={false}
                                initialValue={0}
                        />
                        <NumberInput
                                source="kwota"
                                label="Kwota"
                                helperText={false}
                                initialValue={0}
                        />
                        <TextInput
                                source="info.uwagi"
                                label="Uwagi"
                                rows={4}
                                multiline
                                fullWidth
                        />

                </SimpleForm>
)};

   




export const PrzychodyEdit = props => (
    <Edit {...props} transform={(data) => przychodZapis(przychodFormat(data))}>
            <PrzychodyForm {...props} />
    </Edit>
);

export const PrzychodyCreate = props => (
    <Create {...props} transform={(data) => przychodZapis(przychodFormat(data))}>
            <PrzychodyForm {...props} />
    </Create>
);
