import * as React from "react";
import { SaveButton } from 'react-admin';
import { Grid, Tooltip, Typography } from "@material-ui/core";
import OdwiedzinyNoclegOsoba from "./OdwiedzinyNocleg";

export const OdwiedzinyFormToolbarLarge = props => {

  return (
    <Grid container spacing={3}>

      <Grid item xs>
        <Tooltip
          title="Zapisz zmodyfikowane informacje dotyczące tego Zgłoszenia"
          placement="top-start"
          disableFocusListener
          disableTouchListener
        >
          <Typography align="left">
            <SaveButton
              {...props}
              label="Zapisz zmiany"
              disabled={props.pristine}
            />
          </Typography>
        </Tooltip>
      </Grid>

      <Grid item xs>
        <OdwiedzinyNoclegOsoba {...props} />
      </Grid>

    </Grid>
  );
};
