import * as React from "react";

import { useInput } from 'react-admin';
import DateUtils from "@date-io/dayjs";
import plLocale from 'dayjs/locale/pl';
// import DateFnsUtils from "@date-io/date-fns";
// import format from "date-fns/format";
// import plLocale from "date-fns/locale/pl";
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useFormState } from 'react-final-form';
// import dayjs from "dayjs";

// class LocalizedUtils extends DateFnsUtils {
//     getDatePickerHeaderText(date) {
//         // return date.format("dddd, D MMMM");
//         return format(date, "EEEE, d MMMM", { locale: plLocale });
//     }
// }
class LocalizedUtils extends DateUtils {
    getDatePickerHeaderText(date) {
        return date.format("dddd, D MMMM");
    }
}
    
const DatyField = props => {

        const { values } = useFormState();
        const { input } = useInput(props); // ten hook dołącza ra-propsy do obcego komponentu

        var val = input.value ? input.value : null; // musi być taki fikołek, żeby nie podstawiało aktualnej daty przy pustym polu

        // console.log("Daty input:",input);
        // console.log("Daty props:",props);

        return (
                <MuiPickersUtilsProvider utils={LocalizedUtils} locale={plLocale}>
                <DatePicker 
                        {...props}
                        {...input}
                        clearable
                        inputVariant="outlined"
                        // format='yyyy-MM-dd' // format dla date-fns
                        format='YYYY-MM-DD' // format dla dayjs
                        animateYearScrolling
                        autoOk
                        cancelLabel="ANULUJ"
                        clearLabel="WYCZYŚĆ"
                        margin="dense"
                        invalidDateMessage="data jest nieprawidłowa"
                        minDate = { props.source === 'do' && values.od != null ? values.od : undefined }
                        // maxDate = { props.source === 'od' && values.do != null ? values.do : new Date() }
                        value={val}
                        />
                </MuiPickersUtilsProvider>
        );
};

export default DatyField;



export const KeyDatyField = props => {

        // const { values } = useFormState();
        const { input } = useInput(props); // ten hook dołącza ra-propsy do obcego komponentu
        var val = input.value ? input.value : null; // musi być taki fikołek, żeby nie podstawiało aktualnej daty przy pustym polu

        return (
                <MuiPickersUtilsProvider utils={LocalizedUtils} locale={plLocale}>
                        <KeyboardDatePicker
                                {...props}
                                {...input}
                                clearable
                                inputVariant="outlined"
                                format='YYYY-MM-DD' // format dla dayjs
                                animateYearScrolling
                                autoOk
                                cancelLabel="ANULUJ"
                                clearLabel="WYCZYŚĆ"
                                margin="dense"
                                invalidDateMessage="data jest nieprawidłowa"
                                // openTo="year"
                                views={["year", "month", "date"]}
                                value={val}
                        />
                </MuiPickersUtilsProvider>
        );
};





