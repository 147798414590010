import { parseHTML } from "./parseHTML";
import dayjs from 'dayjs';
import { PeselDecode } from "./pesel";
import { clean } from "fast-clean";
import { db } from "../security/dataProviderDB";

// formatowanie obiektu do wyświetlenia na ekranie
export const osobaFormat = (props,values=null) => {

        let osoba = osobaInit();
        // console.log("format:",props);
        // console.log("val:",values);


        if (props) {

                // brak poniższego blokowało formularz - czemu?
                if (!props.info || props.info.length===0) props.info = osoba.info; // jeśli info jest pustą tablicą to nadpisz domyślnymi wartościami
                // props = clean(props, {nullCleaner: true}); // wyczyszczenie starego formatu i pustych danych
                Object.entries(props).forEach(([k,v]) => { osoba[k] = v }); // przepisanie właściwości z propsa na dane

                if (osoba.pesel && osoba.pesel.length > 5) {
                        osoba.ur = PeselDecode(osoba.pesel).valid ? PeselDecode(osoba.pesel).date : (osoba.info.dataur ? osoba.info.dataur : PeselDecode(osoba.pesel).date);
                }
                if (dayjs(osoba.ur).isValid()) {
                        osoba.wiekdzis = dayjs().diff(osoba.ur, 'y');
                } else { osoba.ur = ''; }
                osoba.info.dataur = osoba.info.dataur ? dayjs(osoba.info.dataur).format("YYYY-MM-DD") : osoba.ur
                // if (osoba.info.dataur===null) osoba.info.dataur = '';

                osoba.a                 = dayjs(props.a).format();
                osoba.d                 = dayjs(props.d).format();

                osoba.imie = parseHTML(osoba.imie);
                osoba.dupa = "blada_zapis";

                // parsowanie daty urodzenia z nru PESEL:
                // osoba.ur = osoba.pesel.lenght > 5 && PeselDecode(osoba.pesel).valid ? PeselDecode(osoba.pesel).date : (osoba.info.dataur ? osoba.info.dataur : 'brak');
                // if (osoba.ur && !osoba.info.dataur) osoba.info.dataur =  dayjs(osoba.ur).format("YYYY-MM-DD");
                // osoba.wiekdzis = osoba.ur ? dayjs().diff(osoba.ur, 'y') : 'niewiadomy';
                // osoba.ur = osoba.pesel.lenght > 5 && PeselDecode(osoba.pesel).valid ? PeselDecode(osoba.pesel).date : (osoba.info ? osoba.info.dataur : 'brak');
                // if (osoba.ur) osoba.info.dataur =  dayjs(osoba.ur).format("YYYY-MM-DD");
                // osoba.wiekdzis = osoba.ur ? dayjs().diff(osoba.ur, 'y') : 'niewiadomy';
        }

        if (values) {
                osoba.wieknoc = osoba.ur && values.od ? dayjs(values.od).diff(osoba.ur, 'y') : 'niewiadomy';
        }

        return osoba;
};

// transkrypcja rekordu z API na dane do zapisania w IDB
export const osobaBaza = (props) => {

        var osoba = {};
        osoba.id        = props.id;
        osoba.imie      = props.imie;
        osoba.nazwisko  = props.nazwisko;
        osoba.pesel     = props.pesel;
        osoba.info      = props.info && JSON.parse(props.info);
        osoba.p         = props.panstwo;
        osoba.d         = dayjs(props.dodane).format();
        osoba.a         = dayjs(props.aktualizacja).format();
        osoba.as        = dayjs(props.aktualizacja).format();
        return osoba;
};

// zdefiniowanie co będzie zapisane w IDB:
export const osobaZapis = (props) => {

        console.log("zapis - początek",props);

        // odczyt i formularz do dubli jest w komponencie OsobaDuble.js
        // problem: brak mechanizmu usuwania połączeń?
        // tutaj jest mechanizm zapisu danych (osobno dla JoomlaUserów i osobno dla Osoby):

        if (props.id) {
                let dubj = []; // najpierw tworzymy jednolitą tablicę wszystkich id
                props.dubju && dubj.push(...props.dubju);
                props.dubjus && dubj.push(props.dubjus);
                db.duble.where("target").equals(props.id) // pobierz wszystkie przypisania zapisane w bazie
                        .and(w => (w.typ === "u"))
                        .toArray().then(przypisania => {
                                przypisania.forEach(p => { // 
                                        if (dubj.includes(p.idos)) { // jeśli przypisanie jest w tablicy zaznaczeń to usuń z tablicy
                                                // console.log("zapis - p usuwany z dubj",p);
                                                dubj = dubj.filter(v => (v !== p.idos));
                                        } else { // jeśli nie ma to usuń z bazy
                                                // console.log("zapis - p usuwany z bazy",p);
                                                db.duble.delete(p.id);
                                                dubj = dubj.filter(v => (v !== p.idos));
                                        }
                                });
                                // console.log("zapis - dubj2",dubj);
                                dubj.forEach(dubel => { // następnie wpisz do bazy to czego jeszcze w bazie nie ma
                                        db.duble.put({ typ: "u", idos: dubel, target: props.id })
                                                .catch(e => console.log("zapis - errors", e));
                                });
                        });

                let dubo = [];
                props.dubos && dubo.push(...props.dubos);
                props.duboso && dubo.push(props.duboso);
                db.duble.where("target").equals(props.id) // pobierz wszystkie przypisania zapisane w bazie
                        .and(w => (w.typ === "o"))
                        .toArray().then(przypisania => {
                                przypisania.forEach(p => { // 
                                        if (dubo.includes(p.idos)) { // jeśli przypisanie jest w tablicy zaznaczeń to usuń z tablicy
                                                // console.log("zapis - p usuwany z dubo",p);
                                                dubo = dubo.filter(v => (v !== p.idos));
                                        } else { // jeśli nie ma to usuń z bazy
                                                // console.log("zapis - p usuwany z bazy",p);
                                                db.duble.delete(p.id);
                                                dubo = dubo.filter(v => (v !== p.idos));
                                        }
                                });
                                // console.log("zapis - dubo2",dubo);
                                dubo.forEach(dubel => { // następnie wpisz do bazy to czego jeszcze w bazie nie ma
                                        db.duble.put({ typ: "o", idos: dubel, target: props.id })
                                                .catch(e => console.log("zapis - errors", e));
                                });
                        });
        }

        //  console.log("zapis - dubj",dubj);
        // let nocleg = noclegInit;
        let osoba = {};

        if (props.id>0) osoba.id = props.id;
        osoba.imie      = props.imie;
        osoba.nazwisko  = props.nazwisko;
        osoba.pesel     = props.pesel;
        osoba.info      = props.info;
        osoba.p         = props.p;
        osoba.d         = dayjs(props.d).format();
        osoba.a         = dayjs().format();

        console.log("zapis - koniec",osoba);

        return clean(osoba); // , {nullCleaner: true}
};

// format informacji w rozwiązywaniu konfliktów:
export const osobaDiff = (props) => {

        let osoba = {};

        // if (props.id>0) nocleg.id = props.id;
        osoba.imie              = props.imie;
        osoba.nazwisko          = props.nazwisko;
        osoba.pesel             = props.pesel;
        osoba.info              = props.info;
        osoba.panstwo           = props.p ? props.p : null;
        osoba.dodane            = dayjs(props.d).format('YYYY-MM-DD HH:mm:ss');
        osoba.aktualizacja      = dayjs(props.a).format('YYYY-MM-DD HH:mm:ss');

        return clean(osoba); // , {nullCleaner: true}
};

// obiekt do wysłania do API:
export const osobaAPI = (props) => {

        let osoba = {};

        osoba.imie              = props.imie;
        osoba.nazwisko          = props.nazwisko;
        osoba.pesel             = props.pesel;
        osoba.info              = JSON.stringify(props.info);
        osoba.panstwo           = props.p;
        osoba.dodane            = dayjs(props.d).format();
        osoba.aktualizacja      = dayjs(props.a).format();

        // właściwości może być mniej lub więcej - ważne by na końcu JSON-a nie było przecinka ;-), bo generuje błąd...
        return clean(osoba); 
};

// kształ początkowy obiektu osoby
export function osobaInit() {
        let osoba = {
        id: '',
        imie: '',
        nazwisko: '',
        pesel: '',
        ur: '',
        info: {
                ulica: '',
                uwagi: '',
                dataur: '',
                ulicanr: '',
                miejsceur: '',
                kodpocztowy: '',
                miejscowosc: ''
        },
        www: {
                id: '',
                user: '',
                mail: '',
                tel: ''
        },
        p: '',
        wieknoc: 'nieokreślone',
        wiekdzis: 'nieokreślone',
        warn: {
                pesel: ''
        },
        a: dayjs().format(),
        d: dayjs().format()
        };

        return osoba;
}
