import dayjs from 'dayjs'
import { clean } from 'fast-clean';

export const kategorie_noclegu = [
    { id: '', label: '' },
    { id: 1, label: 'Chata' },
    { id: 2, label: 'Student' },
    { id: 3, label: 'Namiot' },
    { id: 9, label: 'Lektorium' },
    { id: 4, label: 'Dziecko' },
    { id: 5, label: 'Klub Otrycki' },
    { id: 6, label: 'Gość Gospodarza' },
    { id: 7, label: 'Inne' },
//     { id: 8, label: 'Bank' },// import { osobaFormat } from './osoba';// import { osobaFormat } from './osoba';
];

// formatowanie obiektu do wyświetlenia na ekranie
export const noclegFormat = (props) => {
        
        // dayjs.extend(utc)
        // console.log("daneNoclegu props",props);

        let nocleg = noclegInit();

        if (props.info && props.info.length===0) props.info = nocleg.info; // jeśli info jest pustą tablicą to nadpisz domyślnymi wartościami
        // props = clean(props, {nullCleaner: true}); // wyczyszczenie starego formatu i pustych danych
        Object.entries(props).forEach(([k,v]) => { nocleg[k] = v }); // przepisanie właściwości z propsa na nocleg

        nocleg.dataod_label = dayjs(props.od).format('YYYY-MM-DD');
        nocleg.datado_label = dayjs(props.do).format('YYYY-MM-DD');
        nocleg.nocy = dayjs(nocleg.datado_label).diff(dayjs(nocleg.dataod_label), 'days');

        nocleg.kwota = 0;
        if ( props.kat===7 || props.kat===8 ) { nocleg.kwota = props.o }
        else { nocleg.kwota = oplata(props.kat,props.od,nocleg.nocy); }
        nocleg.oklimat = (( nocleg.nocy - 1 ) * 1.6).toFixed(2);

        nocleg.kategoria_label = props.kat ? kategorie_noclegu.find((item) => item.id === props.kat).label : null;

        return nocleg;
};

// transkrypcja rekordu z API na dane do zapisania w IDB
export const noclegBaza = (props) => {

        // let nocleg = noclegInit;
        let nocleg = noclegInit();

        nocleg.id        = props.id;
        nocleg.od        = dayjs(props.dataod).format('YYYY-MM-DD');
        nocleg.do        = dayjs(props.datado).format('YYYY-MM-DD');
        nocleg.osoba     = props.idosoby;
        nocleg.kat       = props.kategoria;
        nocleg.zw        = props.klimatZwolnieni;
        nocleg.o         = props.oplata;
        nocleg.info      = props.info && JSON.parse(props.info);
        nocleg.d         = dayjs(props.dodane).format();
        nocleg.a         = dayjs(props.aktualizacja).format();
        nocleg.as        = dayjs(props.aktualizacja).format();

        return nocleg;
};

// zdefiniowanie co będzie zapisane w IDB:
export const noclegZapis = (props) => {

        // console.log("zapis - początek",props);

        // let nocleg = noclegInit;
        let nocleg = noclegInit();

        if (props.id>0) nocleg.id = props.id;
        nocleg.od        = dayjs(props.od).format('YYYY-MM-DD');
        nocleg.do        = dayjs(props.do).format('YYYY-MM-DD');
        nocleg.osoba     = props.osoba;
        nocleg.kat       = props.kat;
        nocleg.zw        = props.zw;
        nocleg.o         = props.o ? props.o : null;
        nocleg.info      = props.info;
        nocleg.d         = dayjs(props.d).format();
        nocleg.a         = dayjs().format();

        // console.log("zapis - koniec",nocleg); 

        return clean(nocleg); // , {nullCleaner: true}
};

// obiekt do wysłania do API:
export const noclegAPI = (props) => {

        let nocleg = {};

        nocleg.dataod           = dayjs(props.od).format('YYYY-MM-DD');
        nocleg.datado           = dayjs(props.do).format('YYYY-MM-DD');
        nocleg.idosoby          = props.osoba;
        nocleg.kategoria        = props.kat;
        nocleg.klimatZwolnieni  = props.zw ? 1 : 0;
        nocleg.oplata           = props.o;
        nocleg.info             = props.info ? JSON.stringify(props.info) : null;
        nocleg.dodane           = dayjs(props.d).format();
        nocleg.aktualizacja     = dayjs(props.a).format();

        // właściwości może być mniej lub więcej - ważne by na końcu JSON-a nie było przecinka ;-), bo generuje błąd...
        return clean(nocleg); 
};

// format informacji w rozwiązywaniu konfliktów:
export const noclegDiff = (props) => {

        // console.log("zapis - początek",props);

        // let nocleg = noclegInit;
        let nocleg = {};

        // if (props.id>0) nocleg.id = props.id;
        nocleg.przyjscie        = dayjs(props.od).format('YYYY-MM-DD');
        nocleg.wyjscie          = dayjs(props.do).format('YYYY-MM-DD');
        nocleg.osoba            = 'ID '+props.osoba;
        nocleg.kategoria        = props.kat ? kategorie_noclegu.find((item) => item.id === props.kat).label : null;
        nocleg.klimatyczna      = props.zw ? 'nie pobrana' : 'pobrana';
        nocleg.oplata           = props.o ? props.o+" zł" : "0";
        nocleg.info             = props.info ? props.info : null;
        nocleg.dodane           = dayjs(props.d).format('YYYY-MM-DD HH:mm:ss');
        nocleg.aktualizacja     = dayjs(props.a).format('YYYY-MM-DD HH:mm:ss');

        // console.log("zapis - koniec",nocleg); 

        return clean(nocleg); // , {nullCleaner: true}
};

// Startowe dane noclegu jak są w funkcji, to można mutować obiektu values itp.
// Obiektu zaś nie da się ponownie zainicjalizować - można go tylko mutować - zmieniać jego właściwości
// Na razie nierozpoznane sa wady i zalety, zastosowanie obiektu umożliwia steorwanie wartościami początkowymi
// Do rozpoznania czy istnieje możliwość zmiany wartości początkowych formularza.
// Bo - problem: dodanie noclegu "trzyma" w formularzu wprowadzone wartości - nie wiem jak to odświeżyć.
export function noclegInit() {
        let nocleg = {
                od: dayjs().format('YYYY-MM-DD'),
                do: '',
                osoba: null,
                kat: 1,
                zw: true,
                o: null,
                info: {},
                d: dayjs().format(),
                a: dayjs().format()
        };
        return nocleg;
}








// Walidacja noclegu:
export const validateNocleg = (values) => {
        const errors = {};
        if (!values.osoba) {
                errors.osoba = 'Musisz wskazać osobę';
        } 
        if (!values.od) {
                errors.od = 'Podaj dzień przyjścia';
        }
        //     if (!values.age) {
        //         // You can return translation keys
        //         errors.age = 'ra.validation.required';
        //     } else if (values.age < 18) {
        //         // Or an object if the translation messages need parameters
        //         errors.age = {
        //             message: 'ra.validation.minValue',
        //             args: { min: 18 }
        //         };
        //     }
        return errors
};






// ################################################################################
// CENNIKi -> wyliczenie należnej opłaty
// ################################################################################

const oplata = (kat,od,nocy) => {

        // stawki początkowe - modyfikowane niżej:
        let cena = {
                kat1: 15, // Chata
                kat2: 10, // Student
                kat3: 10, // Namiot
                kat9: 10, // Lektorium
                kat4: 0,  // Dziecko
                kat5: 0,  // KO
                kat6: 0,  // GG
                kat7: 0,  // Inne
                kat8: 0,  // Bank
        };

        // opłata wg cennika do 2018-06-30
        if ( dayjs(od) < dayjs('2018-07-01') ) {
                return cena['kat'+kat] * nocy;
        }

        // opłata wg cennika od 2018-07-01 do 2019-12-31
        if ( dayjs(od) < dayjs('2020-01-01') ) {

                cena.kat1 = 20;
                cena.kat2 = 15;
                
                if ( nocy>5 && ( kat===1 || kat===2 ) ) {
                        return ( nocy - 5 ) * ( cena['kat'+kat] - 5 ) + ( 5 * cena['kat'+kat] );
                } else {
                        return cena['kat'+kat] * nocy;
                }
        }

        // opłata wg cennika od 2020-01-01 do 2020-04-30
        if ( dayjs(od) < dayjs('2020-05-01') ) {

                cena.kat1 = 25;
                cena.kat2 = 20;
                
                return cena['kat'+kat] * nocy;
        }

        // opłata wg cennika od 2020-05-01 do dzisiaj
        else {
                cena.kat1 = 25;
                cena.kat2 = 20;
                cena.kat3 = 15;
                cena.kat9 = 15;

                return cena['kat'+kat] * nocy;
        }
}

// ################################################################################
