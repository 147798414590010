import * as React from "react";
import { useHistory } from 'react-router-dom';
import { db } from "../security/dataProviderDB";
import { tokenRefresh } from "../obliczenia/komunikacja";


const SprawdzeniePrzekierowanie = (props) => {

        const history = useHistory();
        const [noclegow, setNoclegow] = React.useState(0);

        React.useEffect(() => {
                db.noclegi.toCollection().count().then(e => { // jeśli nie ma noclegów - wymuś instalację:
                        if (e === 0) {
                                history.location.pathname !== "/login" && history.location.pathname !== "/roboczy/instalacja" && history.push('/roboczy/instalacja');
                        }
                        setNoclegow(e);
                });
                if (noclegow > 0) { // jeśli apka jest zainstalowana:
                        db.roboczy.where({zmienna:"backup"}).count().then(e => { // wymuś rozwiązanie konflików:
                                if (e !== 0) {
                                        history.location.pathname !== "/roboczy/decyzje" 
                                        && history.location.pathname !== "/roboczy/instalacja" 
                                        && history.push('/roboczy/decyzje');
                                } else {
                                        // jeśli nie ma konfliktów to 
                                        // 5 min. przed upływem ważności tokenu
                                        // odśwież token i zaktualizuj lokalną bazę:
                                        tokenRefresh();
                                        // .then(e=>console.log("token ważny do:",dayjs.unix(e.exp).format(),e));
                                }

                        });
                }
                history.location.pathname==="/roboczy" && history.push('/roboczy/synchronizacja');

        // poniższy komentarz wyłącza warning o braku zależności w React.useEffect
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [history.location.pathname]);

        // console.log("Noclegi check", noclegow, history);
        return (<React.Fragment />);
}

export default SprawdzeniePrzekierowanie;
