import * as React from "react";
import { Button, Card, CardContent, CircularProgress, Typography } from "@material-ui/core";
import { getAPImeta, getAPImiesiecy } from "../obliczenia/komunikacja";
import { db } from "../security/dataProviderDB";

const Instalacja = () => {

        const [pobieranie, setPobieranie] = React.useState(false);
        const [koniec, setKoniec] = React.useState(false);

        async function clickSynchro() {

                setPobieranie(true);
                db.delete().then(() => {
                        console.log("Baza danych usunięta");
                        // console.log("po usunięciu",db);
                    }).catch((err) => {
                        console.error("Could not delete database");
                    }).finally(() => {
                        db.open()
                        .then( async ()=>{
                                console.log("Nowa strutura bazy zainstalowana, rozpoczynam ściąganie danych");
                                showEstimatedQuota();
                                await getAPImiesiecy(12);
                        })
                        .then( async () => {
                                await getAPImeta(6)
                                .then( async data => { // liczba miesięcy wstecz definiowana w .env
                                        await db.roboczy.add({ zmienna: "apiMeta", value: data })
                                });
                        })
                        .then(()=>{
                                showEstimatedQuota();
                                setPobieranie(false);
                                setKoniec(true);
                        });
                    });
        }

        async function showEstimatedQuota() {
                if (navigator.storage && navigator.storage.estimate) {
                  const estimation = await navigator.storage.estimate();
                  console.log(`Quota: ${estimation.quota}`);
                  console.log(`Usage: ${estimation.usage}`);
                } else {
                  console.error("StorageManager not found");
                }
        }
        
                                



        // własciwy komponent:
        return (
          <div style={{
                //   position: 'absolute', 
                //   top: '15px', 
                //   marginLeft: '50%', 
                  textAlign: 'center', 
                  zIndex: '1000'
                }}>
                {/* <Paper  variant="outlined" elevation={3} > */}
                <Card>
                <CardContent className="KM2020">
                <br/>
                <Typography variant="h5">Instalacja</Typography>
                <Typography variant="body1">Aplikacja potrzebuje do działania informacji</Typography>
                <Typography variant="body2">
                        Proces instalacji pobierze niezbędne informacje z serwera. 
                        Może to potrwać od kilku sekund do kilku minut. 
                        Musisz być w zasięgu internetu.
                </Typography>
                <Button onClick={()=>clickSynchro()} disabled={pobieranie} variant="outlined" color="primary" >Uruchom instalację</Button>
                <br/>
                <Typography variant="body1">UWAGA: uruchomienie instalacji skasuje dane i nadpisze serwerowymi!!!</Typography>
                {pobieranie && <CircularProgress />}
                {pobieranie && <Typography>czekaj: instalacja w toku</Typography>}
                {koniec && <Typography>Instalacja zakończona</Typography>}
                
                <br/><br/>

                {/* <Typography>bazy zsynchronizowane</Typography> */}
                </CardContent>
                </Card>
                {/* </Paper> */}

          </div>
        );
};

export default Instalacja;
