import * as React from "react";
import { 
    List, 
    SimpleList, 
    Datagrid, 
    TextField, 
    DateField, 
    // FunctionField,
    NumberField
  } from 'react-admin';
import { 
    useMediaQuery
} from "@material-ui/core";


// #/odwiedziny?sort=od&order=DESC

export const WydatkiLista = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    return (
    <List 
        {...props} 
        sort={{ field: 'id', order: 'DESC' }}
        exporter={false}
    >
        {isSmall ? (
        <SimpleList
            primaryText={(record) => <React.Fragment>
            <small>{record.id}.</small> {record.dzien} - kwota: <b>{record.kwota}</b> zł<br/>
            {record.tytul}
            </React.Fragment>}
            // secondaryText={NoclegUwagi}
            // tertiaryText={record => new Date(record.dodane).toLocaleDateString()}
            // linkType={record => record.canEdit ? "edit" : "show"}
            linkType={"edit"}
            // rowStyle={postRowStyle}
        />
        ) : (
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <DateField source="dzien" locales="sv-SV" />
            <TextField source="tytul" />
            <NumberField source="kwota" />
            {/* <DateField source="dlug" />
            <NumberField source="wykonawca" />
            <TextField source="wierzyciel" />
            <TextField source="splacone" />
            <TextField source="info" />
            <DateField source="d" />
            <DateField source="a" />
            <DateField source="as" /> */}
        </Datagrid>
        )}
    </List>
    );
}

