import * as React from "react";
import { Toolbar } from 'react-admin';
import { useFormState } from 'react-final-form';
import { useMediaQuery } from "@material-ui/core";

import { OsobaFormToolbarLarge } from "./OsobaFormToolbarLarge";
import { OsobaFormToolbarMobile } from "./OsobaFormToolbarMobile";

// const useStyles = makeStyles(

// {  
//     szeroko: {
//         width: '100%',
//         height: '4em',
//         color: 'green'
//       },
// }
  // theme => ({
  //     szeroko: {
  //       // width: '100%',
  //       height: '4em',
  //       marginTop: theme.spacing(2),
  //     },
      // toolbar: {
      //     // backgroundColor:
      //     //     theme.palette.type === 'light'
      //     //         ? theme.palette.grey[100]
      //     //         : theme.palette.grey[900],
      // },
      // desktopToolbar: {
        // width: '100%',
        // height: '4em'
        // marginTop: theme.spacing(2),
      // },
      // mobileToolbar: {
      //     position: 'fixed',
      //     bottom: 0,
      //     left: 0,
      //     right: 0,
      //     padding: '16px',
      //     width: '100%',
      //     boxSizing: 'border-box',
      //     flexShrink: 0,
      //     zIndex: 2,
      // },
      // defaultToolbar: {
      //     flex: 1,
      //     display: 'flex',
      //     justifyContent: 'space-between',
      // },
      // spacer: {
      //     [theme.breakpoints.down('xs')]: {
      //         height: '5em',
      //     },
      // },
//   }),
//   //   // },
//     { name: 'RaToolbar' }
// );


export const OsobaFormToolbar = props => {

        const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
        // const classes = useStyles(props);
        const { values } = useFormState();

        values.dotkniety = props.pristine;
        if (props.from==="noclegi" && props.record.info && props.record.info.juser) {
          values.dotkniety = false;
        }

        // if(values.duble&&values.duble[0]>0) { // gdy jest zaznaczona istniejąca osoba
        //   // tych osób może być kilka - co wtedy?
        //   // trzeba dodać informację o połączeniu - ale to w formularzu dodawania noclegu
        //   // console.log("record-duble",props.record);
        //   values.new_nocleg = {};
        //   values.new_nocleg.osoba = values.duble[0];
        //   values.new_nocleg.od = values.od;
        //   values.new_nocleg.do = values.do;
        //   values.new_nocleg.info = { juser: values.kto };
        //   values.new_osoba = null;
        // } else { // gdy dodajemy nową osobę - przekazać jej dane i dane noclegu
        //   // console.log("brak-duble",props.record);
        //   values.new_nocleg = null;
        // }
        // // console.log("FT props-values",props,values);

    
        return (
          <Toolbar {...props} >
            {isSmall ? (
                <OsobaFormToolbarMobile {...props} />
            ) : (
                <OsobaFormToolbarLarge {...props} />
            )}
          </Toolbar>
        );
};
