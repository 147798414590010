import * as React from "react";
import dayjs from "dayjs";
import { diff } from "just-diff";
import { 
        List, 
        Datagrid, 
        TextField, 
        Show, 
        useRefresh, 
        useNotify
} from 'react-admin';
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import JSONTree from 'react-json-tree'

import { db } from "../security/dataProviderDB";
import { noclegDiff, noclegAPI } from "../obliczenia/nocleg";
import { osobaAPI, osobaDiff } from "../obliczenia/osoba";
import { przychodAPI, przychodDiff } from "../obliczenia/przychod";
import { wydatekAPI, wydatekDiff } from "../obliczenia/wydatek";
import { putAPIrec, tabeleAPImapa } from "../obliczenia/komunikacja";
import { odwiedzinyAPI, odwiedzinyDiff } from "../obliczenia/odwiedziny";

const useStyles = makeStyles((theme) => ({
        root: {
                padding: theme.spacing(2),
        },
        right: {
                [theme.breakpoints.up('sm')]: {
                        textAlign: 'right',
                },
        },
}));


const KonfliktShow = props => {
  
        const [orygToAPI, setOrygToAPI] = React.useState({}); // meta-informacje do migracji
        const [idbRec, setIDBrec] = React.useState({}); // meta-informacje do migracji
        const [apiRec, setAPIrec] = React.useState({}); // meta-informacje do migracji
        const [roznice, setRoznice] = React.useState([]); // meta-informacje do migracji
        const notify = useNotify();
        const refresh = props.refresh;
        // const redirect = useRedirect();
        // console.log(props);
    
        const classes = useStyles();
        let tabela = props.record.typ;
        let idbOryg = props.record.value;

        React.useEffect(() => {
                db[tabela].get(idbOryg.id).then(e => {
                        // console.log("effect",e);
                        if (tabela === "osoby") {
                                setOrygToAPI(osobaAPI(idbOryg));
                                setAPIrec(osobaDiff(e));
                                setIDBrec(osobaDiff(idbOryg));
                                setRoznice(diff(osobaDiff(e), osobaDiff(idbOryg)));
                        }
                        if (tabela === "noclegi") {
                                setOrygToAPI(noclegAPI(idbOryg));
                                setAPIrec(noclegDiff(e));
                                setIDBrec(noclegDiff(idbOryg));
                                setRoznice(diff(noclegDiff(e), noclegDiff(idbOryg)));
                        }
                        if (tabela === "przychody") {
                                setOrygToAPI(przychodAPI(idbOryg));
                                setAPIrec(przychodDiff(e));
                                setIDBrec(przychodDiff(idbOryg));
                                setRoznice(diff(przychodDiff(e), przychodDiff(idbOryg)));
                        }
                        if (tabela === "wydatki") {
                                setOrygToAPI(wydatekAPI(idbOryg));
                                setAPIrec(wydatekDiff(e));
                                setIDBrec(wydatekDiff(idbOryg));
                                setRoznice(diff(wydatekDiff(e), wydatekDiff(idbOryg)));
                        }
                        if (tabela === "odwiedziny") {
                                setOrygToAPI(odwiedzinyAPI(idbOryg));
                                setAPIrec(odwiedzinyDiff(e));
                                setIDBrec(odwiedzinyDiff(idbOryg));
                                setRoznice(diff(odwiedzinyDiff(e), odwiedzinyDiff(idbOryg)));
                        }
                });
        }, [tabela,idbOryg]);

        async function zachowajLokalny() {
                console.log("zachowajLokalny",idbOryg);
                await db[tabela].update(idbOryg.id, idbOryg)
                        .then( async e=>{
                                await putAPIrec(tabeleAPImapa[tabela],idbOryg.id,orygToAPI)
                                .then( async e => {
                                        await db.roboczy.delete(props.record.id)
                                        .then(()=>refresh());
                                        notify('putAPI - rozwiazanie - zachowanie lokalnego',);
                                        // console.log("putAPI - rozwiazanie",e);
                                })
                                .catch( e => {
                                        db.roboczy.add({zmienna: "toSend", typ: tabela, value: idbOryg})
                                        .then( async () => await db.roboczy.delete(props.record.id).then(()=>refresh()));
                                        console.log("error getAPIlist in getAPIall:",e);
                                        notify('putAPI - rozwiazanie - zachowanie lokalnego - w kolejce do wysłania jak będzie internet');
                                });
                        })
                        .catch( e => {
                                console.log("error getAPIlist in getAPIall:",e);
                        });
                // czynności: putIDB(idbRec), usuń z backup, wyślij do API (a jak off-line?)
                // wątpliwość: jak zachowam to przy imporcie znowu wrzuci do backupu?
                // może wrzucać takie rekordy do IDB - typ: doWyslania?

        }

        async function zachowajZdalny() {
                await db.roboczy.delete(props.record.id)
                .then(()=>refresh());
                notify('putAPI - rozwiazanie - zachowanie zdalnego');
                // console.log("zachowajZdalny",idbOryg);
                // czynności: po prostu usuń pozycję z backup
        }

        async function zachowajJakoNowy() {
                delete idbOryg.id;
                idbOryg.d = dayjs().format();
                idbOryg.a = dayjs().format();
                // console.log("zachowajJakoNowy",idbOryg);
                await db[tabela].add(idbOryg)
                .then( async () => await db.roboczy.delete(props.record.id)
                        .then(()=>{
                        // redirect('/roboczy/decyzje');
                        refresh();
                }));
                notify('putAPI - rozwiazanie - zachowany jako nowy');
                // czynności: dodaj do IDB i API jako nowy rekord
        }
        
        return (
          <Show
            {...props}
            /* disable the app title change when shown */
            title=" "
          >
                        <Grid container spacing={3} className={classes.root}>
                                <Grid item xs={12} sm={6} className={classes.right}><b>Różnice:</b></Grid>
                                <Grid item xs={12} sm={6}>
                                        {roznice.map((e) => {
                                                return (
                                                        <div>
                                                                <b>{e.path[0]}</b>: {e.value}
                                                        </div>
                                                );
                                        })}
                                        <br/>
                                        <Button onClick={()=>zachowajJakoNowy()} color="primary" variant="outlined">zachowaj jako nowy</Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                        Wersja pobrana z <b>serwera</b>:
                                        <JSONTree
                                                data={apiRec}
                                                theme="greenscreen"
                                                // sortObjectKeys
                                                shouldExpandNode={() => true}
                                                invertTheme
                                                hideRoot
                                        />
                                        <Button onClick={()=>zachowajZdalny()} color="primary" variant="outlined">zachowaj tę wersję (zdalną)</Button>
                                        
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                        Wersja istniejąca <b>lokalnie</b>:
                                        <JSONTree
                                                data={idbRec}
                                                theme="greenscreen"
                                                // sortObjectKeys
                                                shouldExpandNode={() => true}
                                                invertTheme
                                                hideRoot
                                        />
                                        <Button onClick={()=>zachowajLokalny()} color="primary" variant="outlined">zachowaj tę wersję (lokalną)</Button>
                                       
                                </Grid>
                        </Grid>
          </Show>
        );
}

const KonfliktyLista = (props) => {
        const refresh = useRefresh(); // odświeżenie komponentu po decyzji
        return (
                <div>
                        <Typography>
                                Poniżej konflikty danych: lista pozycji, 
                                dla których są jakieś różnice między wersją na serwerze 
                                a tą istniejącą lokalnie. 
                                Dla każdej pozycji należy podjąć <b>decyzję</b>: 
                                zostawić jedną z wersji? 
                                Czy zapisać lokalną jako nową pozycję?
                                Jeśli lista jest pusta - można normalnie działać.
                        </Typography>
                        <List
                                {...props}
                                filter={{ "zmienna": "backup" }}
                                showNotifications={false}
                                title="Decyzje"
                        >
                                <Datagrid rowClick="expand" expand={<KonfliktShow refresh={refresh} />}>
                                        <TextField source="id" />
                                        <TextField source="typ" />
                                        <TextField source="value.id" label="Identyfikator wiersza" />
                                </Datagrid>
                        </List>
                </div>
        )
}

export default KonfliktyLista;
