// import Dexie from 'dexie'
// import dexieDataProvider from 'ra-data-dexie';
import dexieDataProvider from './dexieProvider';
// import { useLiveQuery } from "dexie-react-hooks";
// import config from "../config.json";
import Dexie from 'dexie'

// import { Osoby } from './daneAPI';

// const dbname = process.env.REACT_APP_IDB_NAME;
// const struktura = JSON.parse(process.env.REACT_APP_IDB_STRUKTURA);

const dbname = "KM2020";
const struktura1 ={
	osoby:"++id,imie,nazwisko,pesel,a",
	noclegi:"++id,od,do,osoba,kat,zw,d",
	roboczy:"++id,last_check"
};
const struktura2 ={
	osoby:"++id,imie,nazwisko,pesel",
};
const struktura3 ={
	osoby:"++id,imie,nazwisko",
};
const struktura4 ={
	osoby:"++id,imie",
};
const struktura5 ={
	osoby:"++id,imie,nazwisko,pesel,a",
};
// const struktura6 ={
// 	osoby:"++id,imie,nazwisko",
// 	noclegi:"++id,od,do,osoba,kat,zw,d",
// };
// const struktura2 ={
// 	osoby:"++id,imie,nazwisko,pesel,a",
// 	noclegi:"++id,od,do,osoba,kat,zw,d",
// 	przychody:"++id,dzien,produkt",
// 	wydatki:"++id,dzien,typ,osoba",
// 	inwentarz:"++id",
// 	roboczy:"++id,zmienna"
// };
// const struktura3 = {
// 	osoby:"++id,imie,nazwisko,pesel,d,a",
// 	noclegi:"++id,od,do,osoba,kat,zw,d,a",
// 	przychody:"++id,dzien,gadzet,d,a",
// 	wydatki:"++id,dzien,typ,osoba,d,a",
// 	inwentarz:"++id,d,a",
// 	roboczy:"++id,zmienna"
// };
// const struktura4 = {
// 	osoby:"++id,imie,nazwisko,pesel,d,a",
// 	noclegi:"++id,od,do,osoba,kat,zw,d,a",
// 	przychody:"++id,dzien,gadzet,d,a",
// 	wydatki:"++id,dzien,typ,osoba,d,a",
// 	odwiedziny:"++id,kto,od,do,liczba,stan,nch,d,a",
// 	jusers:"++id,username,pesel",
// 	inwentarz:"++id,d,a",
// 	roboczy:"++id,zmienna"
// };
// const struktura5 = {
// 	osoby:"++id,imie,nazwisko,pesel,d,a",
// 	noclegi:"++id,od,do,osoba,kat,zw,d,a",
// 	przychody:"++id,dzien,gadzet,d,a",
// 	wydatki:"++id,dzien,typ,osoba,d,a",
// 	odwiedziny:"++id,kto,od,do,liczba,stan,nch,d,a",
// 	jusers:"++id,username,pesel",
// 	inwentarz:"++id,d,a",
// 	roboczy:"++id,zmienna",
// 	duble:"++id,typ,idos,target"
// };
const struktura6 = {
	osoby:"++id,imie,nazwisko,pesel,d,a",
	noclegi:"++id,od,do,osoba,kat,zw,d,a",
	przychody:"++id,dzien,gadzet,d,a",
	wydatki:"++id,dzien,typ,osoba,d,a",
	odwiedziny:"++id,kto,od,do,liczba,state,nch,d,a",
	jusers:"++id,username,pesel",
	inwentarz:"++id,d,a",
	roboczy:"++id,zmienna",
	duble:"++id,typ,idos,target"
};

// const domena = process.env.REACT_APP_API_DOMAIN
// const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
// const dbname = process.env.REACT_APP_IDB_NAME;
// const struktura = JSON.parse(process.env.REACT_APP_IDB_STRUKTURA);

const db = new Dexie(dbname);
db.version(1).stores(struktura1);
db.version(2).stores(struktura2);
db.version(3).stores(struktura3);
db.version(4).stores(struktura4);
db.version(5).stores(struktura5);
db.version(6).stores(struktura6);

const dataProvider = dexieDataProvider(db);
// const dataProvider = dexieDataProvider(dbname,1,struktura4);
// const dataProvider = dexieDataProvider(db);

export { db };

export default dataProvider;


