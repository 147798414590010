import React, { useState } from 'react';
import { useFormState } from 'react-final-form';
import { AutocompleteInput, ReferenceInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { parseHTML } from '../../obliczenia/parseHTML';
import { OsobaFormDialog } from './OsobaFormDialog';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center'
    }
});

const spySubscription = { values: true }; // nie wiem do czego to potrzebne, ale (chyba) potrzebne...

const inputText = choice => { return parseHTML(choice.imie)+' '+choice.nazwisko; }; // wskazana / wybrana opcja

const matchSug = (filterValue, choice) => { // zdecydowanie które opcje spełniają podany tu warunek - bolean

    // console.log("matchsug",filterValue,choice);
    // if (!filterValue) return false;

        let sz = filterValue.split(" ");
        let szukam1 = new RegExp(sz[0], "i");
        let szukam2 = new RegExp(sz[1], "i");
        let szukam3 = new RegExp(sz[2], "i");
        let label = choice.nazwisko+" "+choice.imie+" "+choice.pesel;

        return szukam1.test( label ) && szukam2.test( label ) && szukam3.test( label );

}

const OptionRenderer = choice => { // lista opcji spełniajacych warunek szukania
        
    // console.log("OptRen",choice);

    if (choice.record.nazwisko) {
        let imie = parseHTML(choice.record.imie);
        return (
            <span>
                {choice.record.nazwisko} {imie} - PESEL: {choice.record.pesel}
            </span>
        );
    } else {
        return (
            <span>
                Dodaj: {choice.filterValue}
            </span>
        );
    }
};





export const OsobaInputField = props => {

    const classes = useStyles();
    const { values } = useFormState({ subscription: spySubscription });
    // const { values } = useFormState();
    const [tekst, setTekst] = useState();
    const [showDialog, setShowDialog] = useState(true);

    // let options = useLiveQuery(() => db.osoby.orderBy('nazwisko').toArray(), []);
    // const [version, setVersion] = useState(0);
    // const handleChange = useCallback(() => setVersion(version + 1), [version]);
    // const notify = useNotify();
    // const error = {};
    // const warning = {};
    

    // const {
    //     choices, // An array of records matching both the current input value and the filters
    //     error, // A potential error that may have occured while fetching the data
    //     warning, // A potential warning regarding missing references 
    //     loaded, // boolean that is false until the data is available
    //     loading, // boolean that is true on mount, and false once the data was fetched
    //     setFilter, // a callback to update the filters, e.g. setFilters({ q: 'query' })
    //     setPagination, // a callback to change the pagination, e.g. setPagination({ page: 2, perPage: 50 })
    //     setSort, // a callback to change the sort, e.g. setSort({ field: 'name', order: 'DESC' })
    //     setSortForList, // a callback to set the sort with the same signature as the one from the ListContext. This is required to avoid breaking backward compatibility and will be removed in v4
    // } = useReferenceArrayInputContext();

    // // const form = useForm();
    // console.log("NocForm-notify:",notify);
    // console.log("OsRefVals:",props);

    return (
        <div className={classes.root}>
            <ReferenceInput
                key={0}
                // error="ala ma kotka"
                // warning="cela ma pieska"
                // filterToQuery={(e)=>e.nazwisko}
                filterToQuery={()=>true}
                {...props} 
            >
                <AutocompleteInput 
                    // FormHelperTextProps={(a,b,c)=>{ console.log("Helper-abc:",a,b,c)}}
                    label="Osoba"
                    variant="outlined"
                    suggestionLimit={8}
                    // shouldRenderSuggestions={(searchText) => searchText.length > 2}
                    // setFilter={e=>{console.log("sF",e)}}
                    matchSuggestion={(filterValue, choice) => matchSug(filterValue, choice)}
                    inputText={inputText}
                    // emptyText="ala ma kota" 
                    // createLabel="ala ma kota"
                    // optionText={optionRenderer}
                    // noOptionsText={tekst}
                    // optionValue={"id"}
                    optionText={<OptionRenderer />}
                    resettable
                    limitChoicesToValue
                    helperText={false}
                    onSelect={(a)=>{ if (a.name) { setTekst(a.name); setShowDialog(true); console.log(a); }}}
                    create={ values.osoba ? false : <OsobaFormDialog 
                        // onChange={handleChange} 
                        tekst={tekst} 
                        // show={true}
                        showDialog={showDialog} 
                        setShowDialog={setShowDialog} 
                        /> 
                    }
                />
            </ReferenceInput>
            <br/><br/><br/>
        </div>
    );
};
