import dayjs from 'dayjs'
// import { clean } from 'fast-clean';

// formatowanie obiektu do wyświetlenia na ekranie
// export const inwentarzFormat = (props) => {
        
//         let inwentarz = inwentarzInit();

//         if (props.info && props.info.length===0) props.info = inwentarz.info; // jeśli info jest pustą tablicą to nadpisz domyślnymi wartościami
//         // props = clean(props, {nullCleaner: true}); // wyczyszczenie starego formatu i pustych danych
//         Object.entries(props).forEach(([k,v]) => { inwentarz[k] = v }); // przepisanie właściwości z propsa na inwentarz

//         inwentarz.dataod_label = dayjs(props.od).format('YYYY-MM-DD');
//         inwentarz.datado_label = dayjs(props.do).format('YYYY-MM-DD');
//         inwentarz.nocy = dayjs(inwentarz.datado_label).diff(dayjs(inwentarz.dataod_label), 'days');

//         inwentarz.kwota = 0;

//         return inwentarz;
// };

// transkrypcja rekordu z API na dane do zapisania w IDB
export const inwentarzBaza = (props) => {

        // let inwentarz = inwentarzInit;
        let inwentarz = {};

        inwentarz.id            = props.id;
        inwentarz.nazwa         = props.nazwa;
        inwentarz.opis          = props.opis;
        inwentarz.info          = props.info;
        inwentarz.d             = dayjs(props.dodane).format();
        inwentarz.a             = dayjs(props.aktualizacja).format();

        return inwentarz;
};


// zdefiniowanie co będzie zapisane w IDB:
// export const inwentarzZapis = (props) => {

//         // console.log("zapis - początek",props);

//         // let inwentarz = inwentarzInit;
//         let inwentarz = inwentarzInit();

//         if (props.id>0) inwentarz.id = props.id;
//         inwentarz.dzien     = dayjs(props.dzien).format('YYYY-MM-DD');
//         inwentarz.gadzet    = props.gadzet;
//         inwentarz.liczba    = props.liczba;
//         inwentarz.kwota     = props.kwota;
//         inwentarz.info      = props.info;
//         inwentarz.d         = dayjs(props.d).format();
//         inwentarz.a         = dayjs().format();

//         // console.log("zapis - koniec",inwentarz); 

//         return clean(inwentarz); // , {nullCleaner: true}
// };

// // format informacji w rozwiązywaniu konfliktów:
// export const inwentarzDiff = (props) => {

//         let inwentarz = {};

//         // if (props.id>0) nocleg.id = props.id;
//         inwentarz.dzien          = dayjs(props.dzien).format('YYYY-MM-DD');
//         inwentarz.gadzet         = props.gadzet;
//         inwentarz.liczba         = props.liczba;
//         inwentarz.kwota          = props.kwota;
//         inwentarz.info           = props.info;
//         inwentarz.dodane         = dayjs(props.d).format('YYYY-MM-DD HH:mm:ss');
//         inwentarz.aktualizacja   = dayjs(props.a).format('YYYY-MM-DD HH:mm:ss');

//         return clean(inwentarz); // , {nullCleaner: true}
// };

// // obiekt do wysłania do API:
// export const inwentarzAPI = (props) => {

//         let inwentarz = {};

//         inwentarz.id             = props.id;
//         inwentarz.data           = dayjs(props.dzien).format('YYYY-MM-DD');
//         inwentarz.gadzet         = props.gadzet;
//         inwentarz.liczba         = props.liczba;
//         inwentarz.kwota          = props.kwota;
//         inwentarz.info           = JSON.stringify(props.info);
//         inwentarz.dodane         = dayjs(props.d).format();
//         inwentarz.aktualizacja   = dayjs(props.a).format();

//         // właściwości może być mniej lub więcej - ważne by na końcu JSON-a nie było przecinka ;-), bo generuje błąd...
//         return clean(inwentarz); 
// };

// // inicjalizacja obiektu
// export function inwentarzInit() {
//         let inwentarz = {
//                 dzien: dayjs().format('YYYY-MM-DD'),
//                 gadzet: null,
//                 liczba: null,
//                 kwota: null,
//                 info: {},
//                 d: dayjs().format(),
//                 a: dayjs().format()
//         };
//         return inwentarz;
// }



// // Walidacja inwentarzu:
// export const validatePrzychod = (values) => {
//         const errors = {};
//         if (!values.od) {
//                 errors.od = 'Podaj dzień przyjścia';
//         }
//         //     if (!values.age) {
//         //         // You can return translation keys
//         //         errors.age = 'ra.validation.required';
//         //     } else if (values.age < 18) {
//         //         // Or an object if the translation messages need parameters
//         //         errors.age = {
//         //             message: 'ra.validation.minValue',
//         //             args: { min: 18 }
//         //         };
//         //     }
//         return errors
// };




