import * as React from "react";
import { 
        SaveButton, 
        CreateButton,
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { Grid, Typography } from "@material-ui/core";

const PrzychodFormToolbarLarge = props => {
        const { values } = useFormState();
        // console.log("FTL props-values",props,values);
        return (
            <Grid container spacing={3}>
              <Grid item xs>
                <Typography align="left">
                  
                  <SaveButton
                    {...props}
                    label="Zapisz"
                    disabled={props.pristine}
                  />
                </Typography>
              </Grid>
              <Grid item xs>


              </Grid>
              <Grid item xs>
                <Typography align="right">
                  { values.id && <CreateButton 
                        label="Nowy"
                        variant="outlined"
                        color="primary"
                        size="medium"
                  /> }
                </Typography>
              </Grid>
            </Grid>
        );
};
export default PrzychodFormToolbarLarge;