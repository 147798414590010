import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid, Paper } from '@material-ui/core';
import dayjs from 'dayjs';
import * as weekOfYear from 'dayjs/plugin/weekOfYear'
import { db } from '../../security/dataProviderDB';

dayjs.locale('pl');

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  komorka: {
        padding: '0.5em',
        textAlign: 'center',
        fontSize: '.9em'
      },
  legenda: {
        padding: '0.5em',
        fontSize: '1em'
      },
}));

const DzienKalendarza = props => {
        const classes = useStyles();
        let dnr = dayjs(props.dzien).format('D');
        let dname = dayjs(props.dzien).format('dd');

        const [osch, setOsCh] = React.useState(0);
        const [grch, setGrCh] = React.useState(0);
        const [ospoza, setOsPoza] = React.useState(0);
        const [grpoza, setGrPoza] = React.useState(0);
        const [osocz, setOsOcz] = React.useState(0);
        const [grocz, setGrOcz] = React.useState(0);

        React.useEffect(() => {
                db.odwiedziny.filter(w=>{
                        return (
                        w.state === 1
                        && w.od <= props.dzien
                        && w.do > props.dzien
                        && w.nch !== 2
                        );
                }).toArray().then(e=>{
                        let suma=0;
                        e.map(g=>{
                                return suma += g.liczba;
                        });
                        setOsCh(suma);
                        setGrCh(e.length);
                });

                db.odwiedziny.filter(w=>{
                        return ( //state = 1 and noclegchata = 2 and od <= '$data' and do > '$data'
                        w.state === 1
                        && w.od <= props.dzien
                        && w.do > props.dzien
                        && w.nch === 2
                        );
                }).toArray().then(e=>{
                        let suma=0;
                        e.map(g=>{
                                return suma += g.liczba;
                        });
                        setOsPoza(suma);
                        setGrPoza(e.length);
                });

                db.odwiedziny.filter(w=>{
                        return ( // state = 0 and od <= '$data' and do > '$data';
                        w.state === 0
                        && w.od <= props.dzien
                        && w.do > props.dzien
                        );
                }).toArray().then(e=>{
                        let suma=0;
                        e.map(g=>{
                                return suma += g.liczba;
                        });
                        setOsOcz(suma);
                        setGrOcz(e.length);
                });
        }, [props.dzien]);

        return (
                <Grid item xs>
                <Paper elevation={3}>
                  <Typography className={classes.komorka}>
                    <b>{dnr} - {dname}</b>
                    <hr />
                    Ch: <b>{osch}</b>/{grch}
                    <br />
                    poza: {ospoza}/{grpoza}
                    <br />
                    ocz: {osocz}/{ grocz>0 ? <b>{grocz}</b> : grocz}
                  </Typography>
                </Paper>
              </Grid>
        );
}

export default function OdwiedzinyKalendarz() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  dayjs.extend(weekOfYear);
  let pn = dayjs().set('d', 1).format();
  let dni = [];
  let tygodnie = [];
  let t=0;
  let d=0;
  let i=0;
  tygodnie[t] = [];
  while (i<28) {
          if (d > 6) { 
                  d = 0; 
                  t++;
                  tygodnie[t] = [];
                }
          dni[i] = dayjs(pn).add(i, 'day').format();
          tygodnie[t][d] = dni[i];
          i++;
          d++;
  }


//   console.log(tygodnie);


  return (
    <div className={classes.root}>
      <Accordion 
        // defaultExpanded={true}
        onChange={()=>setExpanded(expanded ? false : true)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          { expanded ? <b>Kalendarz odwiedzin:</b> : <b>Rozwiń żeby zobaczyć kalendarz odwiedzin</b> }
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            {tygodnie.map((tydzien) => (
              <Grid container item spacing={1}>
                {tydzien.map((d) => (
                  <DzienKalendarza dzien={d} />
                ))}
              </Grid>
            ))}
              <Grid container item spacing={1}>
              <Typography className={classes.legenda}><u>Legenda:</u><br />
              <i>dzień miesiąca - dzień tygodnia</i><br />
<b>Ch</b> - noclegi w Chacie - zarówno te oznaczone przez moderatora jako "w Chacie" jak i "niezdecydowane" ;-)<br />
<b>poza</b> - noclegi oznaczone przez moderatora jako "poza Chatą", czyli namiot, Lektorium itp.<br />
<b>ocz</b> - zapowiedzi oczekujące na zatwierdzenie przez moderatora.<br />
Liczby oznaczają: <i>liczba osób / liczba grup</i><br />
              </Typography>
              </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
