import * as React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { NoclegiLista } from "./NoclegiLista";
import { NoclegiCreate, NoclegiEdit } from "./NoclegiForm";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const Noclegi = (props) => {

  const classes = useStyles();
  // console.log("Noclegi props",props);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {props.id ? <NoclegiEdit {...props} /> : <NoclegiCreate {...props} />}
        </Grid>
        <Grid item xs={12} md={6}>
          <NoclegiLista {...props} />
        </Grid>
      </Grid>
    </div>
  )
};

export default Noclegi;
