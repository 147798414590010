import dayjs from 'dayjs';
import { validatePolish } from 'validate-polish';

// funkcja parsowania numeru PESEL ściągnięta z netu.
export function PeselDecode(pesel) 
{
    //Sprawdź, czy wszystkie znaki to cyfry
    var cyfry = [];
    for (let i=0;i<11; i++)
    {
      cyfry[i] = parseInt(pesel.substring(i,i+1));
    }

    //Policz rok z uwzględnieniem XIX, XXI, XXII i XXIII wieku
    var rok = 1900+cyfry[0]*10+cyfry[1];
    if (cyfry[2]>=2 && cyfry[2]<8)
      rok+=Math.floor(cyfry[2]/2)*100;
    if (cyfry[2]>=8)
      rok-=100;

    var miesiac = (cyfry[2]%2)*10+cyfry[3];
    var dzien = cyfry[4]*10+cyfry[5];

    // Zdefiniowanie zmiennych wynikowych:
    var urodzony = dayjs(rok+"-"+miesiac+"-"+dzien).format("YYYY-MM-DD");
    var plec = (cyfry[9]%2===1)?"M":"K";
    var valid = validatePolish.pesel(pesel);

    return {valid:valid,sex:plec,date:urodzony};
}
