
import * as React from "react";
import { Grid } from "@material-ui/core";
import OdwiedzinyKalendarz from "./OdwiedzinyKalendarz"
import OdwiedzinyLista from "./OdwiedzinyLista";

const Odwiedziny = props => {
    return (
      <Grid container spacing={3}>
      <Grid item 
        xs={12} 
        // lg={5}
        >
        <OdwiedzinyKalendarz />
      </Grid>
      <Grid item 
        xs={12} 
        // lg={7}
        >
        <OdwiedzinyLista {...props} />
      </Grid>
        </Grid>
    );
}
export default Odwiedziny;
