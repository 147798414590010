import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  doprawej: {

  }
}));

const LegendPop = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <React.Fragment>
      <IconButton 
        color="primary" 
        aria-label="add to shopping cart"
        aria-describedby={id}
        onClick={handleClick}
        justifyContent="flex-end"
        >
                <InfoOutlinedIcon />
        </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography className={classes.typography}>
          {props.children ? props.children : (<React.Fragment>
          
          <p><b>Opis / Instrukcja</b></p>
          <p>Tutaj będzie opis aplikacji wraz z instrucją użytkownika. Na początek podstawowe info:</p>
          <p><b>Aplikacja:</b></p>
          <ul>
            <li>Aplikacja działa w przeglądarce internetowej. Nie trzeba jej instalować, ale trzeba pobrać dane. Na tym polegała "Instalacja".</li>
            <li>Na telefonie w Chrome można wybrać opcję: "Dodaj do ekranu głównego" - to tworzy skrót na pulpicie i uruchomienie strony a pomocą tego skrótu sprawia że aplikacja wygląda jakby była "zainstalowana" na telefonie.</li>
            <li>Dane są zapisywane do bazy wewnątrz przeglądarki. Wysyłane są na serwer co ok. godzinę. Można wymusić dodatkową sychronizację, gdy telefon jest w zasięgu Internetu. Ale bez Internetu też będzie działać.</li>
            <li>Na razie niedopracowane są mechanizmy off-line - gdy pojawi się ekan że odświeżenie strony jest niemożliwe - powinno wystarczyć użycie przycisku "Cofnij", albo w ostateczności zrestartowanie "aplikacji".</li>
            <li>To na razie jest wersja do testowania. Baza też jest testowa - można wprowadzać dowolne dane bez obaw.</li>
            <li>Wszystkie dostrzeżone błędy / problemy / pomysły - notuj i wyślij zbiorczo mailem.</li>
            {/* <li></li> */}
          </ul>
          <p><b>Użycie:</b></p>
          <ul>
            <li>Wyszukiwanie osoby przeszukuje nazwisko, imię i pesel.</li>
            <li>Jeśli nie ma osoby, można ją dodać - wyrazy rozdzielone spacją przepisywane są do formularza w powyższej kolejności.</li>
            <li>Przyciski sterujące (zapisz, wyczyść, nowy itp.) na telefonie rozwiną się po "tapnięciu" w znak plusa.</li>
            <li>Gdy dodasz nocleg - można go zdublować z wyczyszczonym polem osoby - w ten sposób można wprowadzać grupy.</li>
            <li>Docelowo będą tu pozostałe formularze - Wydatki i Przychody, a osoby będą miały wyświetlone informacje ze strony: telefon, mail, Klub itp.</li>
          </ul>
          </React.Fragment>)}
        </Typography>
      </Popover>
    </React.Fragment>
  );
}

export default LegendPop;