import * as React from "react";
import { 
        NumberInput,
        SelectInput,
        TextInput,
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { 
        Accordion, 
        AccordionDetails, 
        AccordionSummary, 
        Grid, 
        makeStyles, 
        Typography 
} from "@material-ui/core";
import dayjs from "dayjs";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import decodeJwt from 'jwt-decode';

import { chata_czy_poza, odwiedzinyFormat, statusy_zgloszenia } from '../../obliczenia/odwiedziny';
import DatyField from "../Daty";
import LegendPop from "../LegendPop"

const useStyles = makeStyles((theme) => ({
        root: {
                // padding: theme.spacing(1),
                margin: '0',
        },
        right: {
                [theme.breakpoints.up('sm')]: {
                        textAlign: 'right',
                },
        },
        full: {
                width: '100%',
        }
    }));
    
const OdwiedzinyFormFields = props => {

        const { values } = useFormState();
        const classes = useStyles();
        const tok = decodeJwt(localStorage.getItem('token'));
        values.zalogowany = tok.roles[0];
        let mvalues = odwiedzinyFormat(values);
        const [expanded, setExpanded] = React.useState(false);
        // console.log("od-mval",mvalues);

        return (
                <React.Fragment>

                        <Typography className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense">
                                {props.record.id && `Zgłoszenia zamiaru odwiedzin - ID: ${props.record.id}`}
                                {!props.record.id && `Dodawanie nowego zgłoszenia`}
                        </Typography>

                        {/* <OdwiedzinyExt {...props} values={values} /> */}
                        <Grid container spacing={3} className={classes.root}>
                                <Grid item xs={12}>
                                        <p>{mvalues.odn} - {mvalues.don} - zgłoszenie <b>{mvalues.state_label}</b></p>
                                        <h4>{values.nazwa} - osób: {values.liczba}</h4>
                                        <p><i>miejsce noclegu:</i> {mvalues.nch_label}</p>
                                        <p>{values.info}</p>

                                        {values.kto_info && <div>
                                                <p>zgłoszenie dodane {dayjs(values.d).format("YYYY-MM-DD HH:mm:ss")} przez: {values.kto_info.name}
                                                        {values.kto_info.email && <span><br />email: {values.kto_info.email}</span>}
                                                        {values.kto_info.telefon && <span><br />telefon: {values.kto_info.telefon}</span>}
                                                        {values.kto_info.pesel && <span><br />PESEL: {values.kto_info.pesel}</span>}
                                                </p>

                                                {values.adm && <p><i>notatki moderatora:<br />{values.adm}</i></p>}
                                                {values.mod_info && <p><i>ostatnio edytowane: {dayjs(values.a).format("YYYY-MM-DD HH:mm:ss")} przez: {values.mod_info.name}</i></p>}

                                        </div>}

                                </Grid>
                        </Grid>

                        <hr />
                        <Accordion 
                                // defaultExpanded={true}
                                onChange={()=>setExpanded(expanded ? false : true)}
                        >
                                <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.root}
                                >
                                        { expanded ? <b>Edycja zgłoszenia:</b> : <b>Rozwiń żeby zatwierdzić / edytować zgłoszenie</b> }
                                </AccordionSummary>
                                <AccordionDetails>
                                        <div className={classes.full}>
                                        <i>1. Informacje moderatora:</i>
                                        <LegendPop>
                                                <p>Co można tutaj zrobić:</p>
                                                <ol>
                                                        <li>Pierwsza część formularza to (poza statusem) informacje widoczne tylko dla moderatorów.</li>
                                                        <li>Druga część to informacje wprowadzone przez zgłaszającego.</li>
                                                        <li>Miejsce noclegu i komentarz moderatora jest widoczny tylko dla moderatorów.</li>
                                                        <li>Istnieje też możliwość utworzenia Noclegu na podstawie Zgłoszenia.</li>
                                                </ol>
                                        </LegendPop>
                                        <br />
                                        <SelectInput
                                                source="state"
                                                label="Status zgłoszenia"
                                                choices={statusy_zgloszenia}
                                                optionText="label"
                                                optionValue="id"
                                                helperText={false}
                                                variant="outlined"
                                        />
                                        <br />
                                        <SelectInput
                                                source="nch"
                                                label="Miejsce noclegu"
                                                choices={chata_czy_poza}
                                                optionText="label"
                                                optionValue="id"
                                                helperText={false}
                                                variant="outlined"
                                        />
                                        <br />

                                        <TextInput
                                                source="adm"
                                                variant="outlined"
                                                label="Komentarz moderatora"
                                                rows={3}
                                                multiline
                                                fullWidth
                                                helperText={false}
                                        />
                                        <hr/>
                                        <p><i>2. Informacje wprowadzone przez zgłaszającego:</i></p>

                                        <TextInput
                                                source="nazwa"
                                                variant="outlined"
                                                label="Nazwa grupy"
                                                fullWidth
                                                helperText={false}
                                        />

                                        <DatyField
                                                source="od"
                                                label="Dzień przyjścia"
                                        />
                                        <br />
                                        <DatyField
                                                source="do"
                                                label="Dzień wyjścia"
                                                minDateMessage="Halo! Data wyjścia musi być później niż data przyjścia!"
                                        />
                                        <br />

                                        <NumberInput
                                                source="liczba"
                                                variant="outlined"
                                                label="Liczba osób"
                                                helperText={false}
                                        />

                                        <TextInput
                                                source="info"
                                                variant="outlined"
                                                label="Opis zgłaszającego"
                                                rows={7}
                                                multiline
                                                fullWidth
                                                helperText={false}
                                        />

                                        
                                        <br />
                                        </div>
                                </AccordionDetails>
                        </Accordion>
                        <br />

                </React.Fragment>
        );

}

export default OdwiedzinyFormFields;