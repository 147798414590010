import * as React from "react";
import { CheckboxGroupInput } from 'react-admin';

import { db } from "../../security/dataProviderDB";
import LegendPop from "../LegendPop";
import { OsobaInputField } from "./OsobaInputField";
import { OsobaInputFieldJU } from "./OsobaInputFieldJU";

const OsobaDuble = props => {

        // const { values } = useFormState();
        const [dubOs, setDubOs] = React.useState(); // wszystkie ids Osoby jakie mają być wyświetlone pod formularzem
        const [dubJU, setDubJU] = React.useState(); // wszystkie ids JoomlaUsers jakie mają być wyświetlone pod formularzem
        const [dubJUChecked, setDubJUChecked] = React.useState([]); // ids JoomlaUsers przypisane (checked)
        const [dubOsChecked, setDubOsChecked] = React.useState([]); // ids JoomlaUsers przypisane (checked)

        React.useEffect(() => {
                // tutaj pobieram informacje o potencjalnych i oznaczonych połączeniach między osobami 
                db.duble.where("target").equals(props.record.id)
                .and(w=>(w.typ==="u"))
                .toArray().then(wierszeu=>{ // pobieram informacje o połączeniach przypisanych do bieżącej osoby
                        let tabJu = [];
                        wierszeu.forEach(wiersz=>{ // przepisz id dubli do nowej tablicy samych identyfikatorów 
                                tabJu.push(wiersz.idos);
                        });
                        setDubJUChecked(tabJu); // ustaw przypisane pozycje

                        db.jusers.filter(u => { // szukam w bazie userów www osób o tym samym nazwisku co edytowana osoba
                                let nazwa = props.record.imie+" "+props.record.nazwisko;
                                return (u.name === nazwa || tabJu.includes(u.id)) // elastyczność filtru do dopracowania 
                        }).toArray().then( e => setDubJU(e) );
                });

                db.duble.where("target").equals(props.record.id)
                .and(w=>(w.typ==="o"))
                .toArray().then(wierszeo=>{ // pobieram informacje o połączeniach przypisanych do bieżącej osoby
                        let tabOs = [];
                        wierszeo.forEach(wiersz=>{ // przepisz id dubli do nowej tablicy samych identyfikatorów 
                                tabOs.push(wiersz.idos);
                        });
                        setDubOsChecked(tabOs); // ustaw przypisane pozycje

                        db.osoby.filter(o => { // szukam w bazie userów www osób o tym samym nazwisku co edytowana osoba
                                return (( 
                                        o.imie === props.record.imie 
                                        && o.nazwisko === props.record.nazwisko 
                                        ) 
                                        || tabOs.includes(o.id)
                                        ) // elastyczność filtru do dopracowania 
                        }).toArray().then( wynik => {
                                let wynik2 = wynik.filter(w=>w.id!==props.record.id); // filtr usuwający bieżacą osobę
                                setDubOs(wynik2);
                        });
                });

// metody czyszczenia tablic:
// let wynik = duJu.concat(e);
// let wynik2 = wynik.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i); // funkcja usuwająca duplikaty
// let wynik3 = wynik2.filter(w=>w.id!==props.record.id); // filtr usuwający bieżacą osobę

        // poniższy komentarz wyłącza warning o braku zależności w React.useEffect
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        // console.log("OsDuble - props,dubOs,dubJU,values:",props,dubOs,dubJU,values);
        return (
                <React.Fragment>

                        <hr/>
                        <b>Osoby połączone</b> 
                        <LegendPop>
                                <p>O co chodzi z łączeniem osób:</p>
                                <ul>
                                        <li>Często się zdarza, że jakaś osoba wprowadzona jest do bazy kilkukrotnie.</li>
                                        <li>Warto te duplikaty wtedy połączyć ze sobą, żeby w wynikach wyszukiwania pojawiała się raz.</li>
                                        <li>Warto też wiedzieć, którym użytkownikiem strony WWW jest wybrana Osoba.</li>
                                        <li>Osoby o tym samym imieniu i nazwisku pojawią sie na liście - można je wskazać.</li>
                                        <li>Można też samodzielnie wyszukać osoby wśród Gości Chaty i/lub wśród użytkowników strony WWW.</li>
                                </ul>
                        </LegendPop>
                        { ((dubOs && dubOs.length>0) || (dubJU && dubJU.length>0)) ? 
                        <p>Zaznaczone pozycje są dołączone do bieżącej osoby:</p>
                        :
                        <p>Na razie brak osób połączonych z tą osobą.</p>
                        }

                        { dubOs && dubOs.length>0 && <React.Fragment>
                                <CheckboxGroupInput
                                        label=""
                                        row
                                        source="dubos"
                                        choices={dubOs} 
                                        defaultValue={dubOsChecked}
                                        optionText={o=>{ return <span>
                                                osobaID: {o.id}. {o.imie} {o.nazwisko} - PESEL: {o.pesel}
                                        </span>}}
                                        optionValue="id"
                                        helperText={false}
                                />
                        </React.Fragment>
                        }
                        { dubJU && dubJU.length>0 && <React.Fragment>
                                <CheckboxGroupInput
                                        label=""
                                        row
                                        source="dubju"
                                        choices={dubJU} 
                                        defaultValue={dubJUChecked}
                                        optionText={o=>{ return <span>
                                                userID: {o.id}. {o.name} - login: {o.username} - mail: {o.email}
                                        </span>}}
                                        helperText={false}
                                />
                        </React.Fragment>
                        }



                        <p>Można wyszukać i wskazać osobę z listy:</p>

                        <OsobaInputField
                                source="duboso"
                                label="Gość Chaty"
                                reference="osoby"
                                perPage={100000}
                                sort={{ field: 'nazwisko', order: 'ASC' }}
                                fullWidth
                        />

                        <OsobaInputFieldJU
                                source="dubjus"
                                label="Użytkownik strony WWW"
                                reference="jusers"
                                perPage={100000}
                                sort={{ field: 'username', order: 'ASC' }}
                                fullWidth
                        />

                </React.Fragment>
        );

}

export default OsobaDuble;
