import { Layout } from 'react-admin';
import MyAppBar from './MyAppBar';
// import MySidebar from './MySidebar';
import Menu from './Menu';
// import MyNotification from './MyNotification';

const MyAppLayout = props => <Layout
    {...props}
    appBar={MyAppBar}
    // {...console.log("layout:",props)}
    // sidebar={MySidebar}
    menu={Menu}
    // notification={MyNotification}
/>;

export default MyAppLayout;
