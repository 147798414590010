
import * as React from "react";
import { 
  List, 
  Datagrid, 
  TextField, 
  Show, 
  SimpleList, 
  DateField, 
  FunctionField,
  Filter,
  SelectInput,
  SortButton,
  TextInput,
} from 'react-admin';
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { chata_czy_poza, statusy_zgloszenia } from "../../obliczenia/odwiedziny";
// import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';

const useStyles = makeStyles((theme) => ({
    root: {
            padding: theme.spacing(2),
    },
    right: {
            [theme.breakpoints.up('sm')]: {
                    textAlign: 'right',
            },
    },
    filtr: {
      width: '100%',
    }
}));

const OdwiedzinyFilter = (props) => {
  const classes = useStyles();
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  // istotny jest jedynie ostatni filtr, dlatego trzeba zablokować wcześniejsze filtry jeśli wybrany jest filtr następny.
  return (
  <Filter {...props}>
    <SelectInput
      source="state"
      alwaysOn
      variant="outlined"
      label="filtruj status"
      className={classes.filtr}
      choices={statusy_zgloszenia}
      optionText="label"
      optionValue="id"
    />
    <SelectInput
      source="nch"
      alwaysOn
      variant="outlined"
      label="Chata czy poza?"
      className={classes.filtr}
      choices={chata_czy_poza}
      optionText="label"
      optionValue="id"
    />
    <TextInput
      source="id"
      alwaysOn
      variant="outlined"
      label="wyszukaj ID"
    />
    { isSmall && <SortButton 
        fields={['od', 'id']} 
        alwaysOn
        variant="outlined"
      /> }
  </Filter>
  );
}

export const OdwiedzinyShow = props => (
  <Show {...props}>
    {/* <OdwiedzinyExt /> */}
  </Show>
);



const OdwiedzinyLista = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    return (
      <List
        {...props}
        title={props.options.label}
        filter={ isSmall && { odwiedziny: true } }
        filters={<OdwiedzinyFilter />}
        sort={{ field: "id", order: "DESC" }}
        bulkActionButtons={false}
        perPage={25}
        exporter={false}
      >
        {isSmall ? (
          <SimpleList
            primaryText={(record) => <React.Fragment>
              <small>{record.id}.</small> {record.nazwa} <small>- {statusy_zgloszenia.filter(i => i.id === record.state)[0].label}</small><br/>
              {record.od} - {record.do}, osób: <b>{record.liczba}</b>
            </React.Fragment>}
            // secondaryText={(record) => `${record.id}. ${record.nazwa}`}
            // tertiaryText={record => new Date(record.published_at).toLocaleDateString()}
            linkType={(record) => (record.canEdit ? "edit" : "show")}
          />
        ) : (
          <Datagrid rowClick="edit">
            <TextField source="id" />
            <DateField source="od" locales="sv-SV" />
            <DateField source="do" locales="sv-SV" />
            <FunctionField label="opis" render={record => {
              return <span>
                {record.nazwa} - osób: <b>{record.liczba}</b>
              </span>
            }} />
            <FunctionField source="state" label="status" render={record => {
              return <span>
                {statusy_zgloszenia.filter(i => i.id === record.state)[0].label}
              </span>
            }} />
          </Datagrid>
        )}
      </List>
    );
}
export default OdwiedzinyLista;