import * as React from "react";
import { useFormState } from 'react-final-form';
import { useLiveQuery } from "dexie-react-hooks";

import { db } from '../../security/dataProviderDB';
import { osobaFormat } from "../../obliczenia/osoba";
import { OsobaFormDialog } from "./OsobaFormDialog";

// czysty JSON - string
const OsobaRaw = (props) => {
    let o = JSON.stringify(props.osoba);
    return (
        <>
            RAW:<br />
            {o}
        </>
    );
}

// dane osoby wyświetlane w formularzu dodawania noclegu:
export const OsobaInfo = (props) => {
    const [showDialog, setShowDialog] = React.useState(false);
    // console.log("OsInfo:",props);
    let id = parseInt(props.o);
    let osoba = useLiveQuery(() => db.osoby.get(id), [id]);
    const { values } = useFormState();
    let o = osobaFormat(osoba,values);
    return (
        <p className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense">
            {/* <Link to={{pathname: '/osoby/'+o.id}}>ID: {o.id}. {o.pesel && <span>PESEL: {o.pesel}, </span>}</Link> */}
            <OsobaFormDialog 
                osoba={osoba} 
                showDialog={showDialog} 
                setShowDialog={setShowDialog} 
                />
            wiek w dniu przyjścia: <b>{o.wieknoc}</b>,
            {o.ur && <span> ur. {o.ur}, </span>}
            {o.info.miejsceur && <span> w: {o.info.miejsceur},</span>}
            
            <br /><br />
            {/* {JSON.stringify(o)} */}
        </p>
    );
}

// dane osoby wyświetlane na liście osób:
export const OsobaSumaLista = (props) => {
    let o = osobaFormat(props.osoba);
    return (
        <OsobaSzczegoly o={o} />
    );
}

// dane osoby wyświetlane w formularzu dodawania osoby:
export const OsobaSuma = () => {
    const { values } = useFormState();
    let o = osobaFormat(values);
    // let o = props.o;
    return (
        <OsobaSzczegoly o={o} />
    );
}

// szczegóły osoby:
const OsobaSzczegoly = (props) => {
    let o = props.o;
    return (
        <p className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense">
            Podsumowanie informacji - ID {o.id}:<br/>
            {o.imie} {o.nazwisko}<br/>{o.pesel && <span>PESEL: {o.pesel}, </span>}<br/>
            wiek dzisiaj: <b>{o.wiekdzis}</b>,<br/>
            {o.ur && <span> ur. {o.ur}, </span>}
            {o.info.miejsceur && <span> w: {o.info.miejsceur},</span>}
            {o.info.uwagi && <span><br/>Uwagi: {o.info.uwagi}</span>}

            
            <br /><br />
            {/* <OsobaFormDialog osoba={o} /> */}
            {/* {JSON.stringify(o.info)} */}
        </p>
    );
}


// nazwisko na liście noclegów:
const OsobaNocleg = (props) => {
    // let o = props.osoba;
    let { osoba } = props
    return (
        <React.Fragment>
            <b>{osoba.imie} {osoba.nazwisko}</b> - <small>PESEL: {osoba.pesel}</small>
        </React.Fragment>
    );
}

export const OsobaDane = (props) => {
    let id = parseInt(props.o);
    let osoba = useLiveQuery(() => db.osoby.get(id), [id]);
    let osobaF = osobaFormat(osoba);
    //     console.log(osoba);
    // console.log(props);

    return (
    <React.Fragment>
        { props.typ === "raw" && <OsobaRaw osoba={osobaF} /> }         
        { props.typ === "nocleg" && <OsobaNocleg osoba={osobaF} /> }         
    </React.Fragment>
    );
}
