import * as React from "react";
import { 
        SimpleForm, 
        Create, 
        Edit, 
} from 'react-admin';
import { odwiedzinyZapis, odwiedzinyFormat } from '../../obliczenia/odwiedziny';
import { OdwiedzinyFormToolbar } from "./OdwiedzinyFormToolbar";
import OdwiedzinyFormFields from "./OdwiedzinyFormFields";



// Walidacja osoby:
const validateOdwiedziny = (values) => {
        const errors = {};

        // console.log("values validate:",values);

        // if (!values.imie) {
        //         errors.imie = 'Wpisz imię';
        // }
        // if (!values.nazwisko) {
        //         errors.nazwisko = 'Wpisz nazwisko';
        // }
        // if (!values.pesel) {
        //         errors.pesel = "Uzupełnij numer PESEL !"
        // }
        // if ( values.pesel && !(values.pesel.length === 11) ) {
        //         errors.pesel = "PESEL musi mieć 11 cyfr"
        // }
        // if ( values.pesel && values.pesel.length === 11 && !PeselDecode(values.pesel).valid ) {
        //         values.warn.pesel = 'PESEL jest niepoprawny, trzeba uzupełnić datę urodzenia i obywatelstwo:';
        // } else { values.warn.pesel = ''; }

        // if (values.warn.pesel && !values.info.hasOwnProperty('dataur') ) {
        //         errors.info.dataur = 'PESEL jest nieprawidłowy - wpisz datę urodzenia';
        // } 

        // if (values.warn.pesel && !values.p) {
        //         errors.p = 'PESEL jest nieprawidłowy - podaj państwo obywatelstwa';
        // }

        return errors
};

const OdwiedzinyForm = props => {

        return (
        <SimpleForm {...props} 
                toolbar={<OdwiedzinyFormToolbar/>} 
                // initialValues={osobaInit} 
                validate={validateOdwiedziny} 
                variant="outlined"
                >

                <OdwiedzinyFormFields {...props} />
        </SimpleForm>
)};


export const OdwiedzinyEdit = props => (
    <Edit {...props} transform={(data) => odwiedzinyZapis(odwiedzinyFormat(data))}>
            <OdwiedzinyForm {...props} />
    </Edit>
);

export const OdwiedzinyCreate = props => (
    <Create {...props} transform={(data) => odwiedzinyZapis(odwiedzinyFormat(data))}>
            <OdwiedzinyForm {...props} />
    </Create>
);
