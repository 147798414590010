import dayjs from "dayjs";
import decodeJwt from 'jwt-decode';
import compare from "just-compare"
import { db } from "../security/dataProviderDB";
import { noclegBaza, noclegAPI, noclegDiff } from "./nocleg";
import { osobaBaza, osobaAPI, osobaDiff } from "./osoba";
import { przychodBaza, przychodAPI, przychodDiff } from "./przychod";
import { wydatekBaza, wydatekAPI, wydatekDiff } from "./wydatek";
import { jusersBaza } from "./jusers";
import { odwiedzinyBaza, odwiedzinyAPI, odwiedzinyDiff } from "./odwiedziny";
import { inwentarzBaza } from "./inwentarz";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT

const tabeleAPI = [ // wskazanie zasobów w API:
        "osobies",
        "noclegis",
        "przychodies",
        "wydatkis",
        "inwentarzs",
        "odwiedzinies",
        "joomla_users"
];

export const tabeleAPImapa = { // zmapowanie zasobów lokalnych i zdalnych
        osoby: "osobies",
        noclegi: "noclegis",
        przychody: "przychodies",
        wydatki: "wydatkis",
        odwiedziny: "odwiedzinies"
}

// Procedura synchronizacji:
// - jeśli aplikacja jest on-line to:
// - najpierw odświeża token
// - następnie pobiera z serwera dane z ostatnich 6 miesięcy
// - porównuje i zapisuje do lokalnej bazy
// - różnice są zapisywane w zasobie "robocze" jako zmienna: backup
// - jeśli są różnice - przekierowanie na decyzje
// - jeśli brak różnic - odświeża apiMeta i idbMeta
// - i wysyła na serwer wszelkie dane aktualizowane po dacie ostatniej aktualizacji na serwerze - osobno dla kazdej tabeli


// funkcja odświażająca token 5 minut przed upływem ważności.
// użyta w SprawdzeniePrzekierownie
export async function tokenRefresh(force) {
        let tok = decodeJwt(localStorage.getItem('token'));
        // console.log("tokRef",tok);
        let exp = dayjs.unix(tok.exp).format();
        let teraz = dayjs().add(5, 'm').format();
        let siec = navigator.onLine ? "jest" : "brak";
        
        if ( ( force || teraz > exp ) && siec === "jest") {
                let a = localStorage.getItem('cred');
                let cred = window.atob(a);
                const request = new Request(process.env.REACT_APP_API_AUTH, {
                        method: 'POST',
                        body: cred,
                        headers: new Headers({ 'Content-Type': 'application/json' }),
                });
                try {
                        const response = await fetch(request);
                        if (response.status < 200 || response.status >= 300) {
                                throw new Error(response.statusText);
                        }
                        const auth = await response.json();
                        localStorage.setItem('token', auth.token);
                        let tokn = decodeJwt(auth.token);
                        // console.log("token odswiezony:",dayjs.unix(tokn.exp).format())

                        await getAPImiesiecy(6).then(async () => {
                          // najpierw trzeba sprawdzić czy są konflikty:
                          await db.roboczy.where({ zmienna: "backup" }).count()
                            .then( async (e) => {
                              tokn.decyzji = e;
                              if (e !== 0) {
                                // - jeśli tak - nie rób nic
                                console.log("konieczne decyzje", e);
                                // tokn.decyzji = e;
                                // return "decyzje";
                              } else {
                                // - jeśli nie to idź dalej - i wyślij dane do API:
                                console.log("decyzji", e);
                                await getAPImeta(6).then(async (apim) => {
                                  // pobranie rekordów do wysłania:
                                  await getIDBmeta(apim).then(async (idbm) => {
                                    await putAPI(idbm, apim) // wysłanie danych z IDB do API
                                      .then(async () => {
                                        await getAPImeta(6) // po zapisaniu danych ponowne pobranie metaAPI
                                          .then(async (apim) => {
                                            await db.roboczy.get({ zmienna: "apiMeta" })
                                              // i zapisanie apiMeta w roboczym IDB
                                              .then((e) => db.roboczy.update(e.id, { value: apim })
                                                  .then(console.log("synchronizacja zakończona..."))
                                              );
                                          });
                                        // potrzebny jakiś mechanizm oznaczania sukcesu / porażki
                                      });
                                  });
                                });
                              }
                            });
                        });
                        return tokn;
                } catch (e) {
                        console.log("problem z fetch",e);
                }
        } else {
                return tok;
        }
}

// pobranie porcji danych z API i wrzucenie do obiektu json
export async function getAPIlist(url) {
        var adres = entrypoint + url;
        const odp = await fetch(adres, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                headers: {
                        'accept': 'application/ld+json',
                        'Authorization': `Bearer ${window.localStorage.getItem("token")}`
                },
        });
        // odświerzanie tokenu jest w SprawdzeniePrzekierowanie
        // jeśli wygasł token to zaloguj ponownie danymi zapisanymi w localStorage:
        // if (odp.status === 401) {
        //         let a = localStorage.getItem('cred');
        //         let cred = window.atob(a);
        //         const request = new Request(process.env.REACT_APP_API_AUTH, {
        //                 method: 'POST',
        //                 body: cred,
        //                 headers: new Headers({ 'Content-Type': 'application/json' }),
        //         });
        //         try {
        //                 const response = await fetch(request);
        //                 if (response.status < 200 || response.status >= 300) {
        //                         throw new Error(response.statusText);
        //                 }
        //                 const auth = await response.json();
        //                 localStorage.setItem('token', auth.token);
        //                 getAPIlist(url);
        //         } catch (e) {
        //                 throw new Error('Network error eeee');
        //         }
        // }
        return odp.json(); // parses JSON response into native JavaScript objects
}

// POST - wysłanie i UTWORZENIE rekordu danych do API i zwrócenie json
export async function postAPIrec(zasob,data) {
        let adres = entrypoint + "/" + zasob;
        const odp = await fetch(adres, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                        'accept': 'application/ld+json',
                        'Content-Type': `application/ld+json`,
                        'Authorization': `Bearer ${window.localStorage.getItem("token")}`
                },
                body: JSON.stringify(data)
        });
        return odp.json(); // parses JSON response into native JavaScript objects
}

// PUT - wysłanie i NADPISANIE rekordu danych do API i zwrócenie json
export async function putAPIrec(zasob,id,data) {
        let adres = entrypoint + "/" + zasob + "/" + id;
        const odp = await fetch(adres, {
                method: 'PUT', // *GET, POST, PUT, DELETE, etc.
                headers: {
                        'accept': 'application/ld+json',
                        'Content-Type': `application/ld+json`,
                        'Authorization': `Bearer ${window.localStorage.getItem("token")}`
                },
                body: JSON.stringify(data)
        });
        return odp.json(); // parses JSON response into native JavaScript objects
}

// GET - pobranie z API meta informacji o danych do pobrania 
export async function getAPImeta(miesiecy=12) {

        let moment = dayjs().subtract(miesiecy, 'month').format(); // ustawiamy zakres czasu na 6 miesięcy od dzisiaj
        let zakres = { doPobrania: 0 };

        for await (let temp of tabeleAPI.map(async source => { // konstrukt for of pozwala na zwrócenie obietnicy jako efektu pętli

                let url = "/" + source + "?order[aktualizacja]=desc&limit=1";

                if ( miesiecy===12 ) {
                        url = url + ( source!=="osobies" ? ("&aktualizacja[after]=" + encodeURIComponent(moment)) : '' );
                } else {
                        url = url + "&aktualizacja[after]=" + encodeURIComponent(moment);
                }
                
                await getAPIlist(url).then(data => {

                        if (data && data['hydra:totalItems'] > 0) {
                                let zak = {};
                                zak.source = source;
                                zak.total = data['hydra:totalItems'];
                                // zak.first = parseInt(data['hydra:view']['hydra:first'].split("=").slice(-1)[0]);
                                // zak.last = Math.ceil(zak.total / 1000); //parseInt(data['hydra:view']['hydra:last'].split("=").slice(-1)[0]);
                                // zak.next = parseInt(data['hydra:view']['hydra:next'].split("=").slice(-1)[0]);
                                zak.aktualizacja = data['hydra:member'][0]['aktualizacja'];

                                zakres[source] = zak;
                                zakres.doPobrania += zak.total;
                                zakres.checkMoment = dayjs().format();
                        }
                })
        }))
        {zakres.smietnik = temp} // ta linia jest tylko po to, żeby ukryć warning o braku użycia temp

        return zakres;
}

// IDB - pobranie z IDB meta informacji o danych do wysłania na podstawie apiMeta
export async function getIDBmeta(apiMeta) {

        let zakres = { 
                doWyslania: 0, 
                checkMoment: dayjs().format(),
                osobies: [],
                noclegis: [],
                przychodies: [],
                wydatkis: [],
                odwiedzinies: []
        };
        // console.log("getIDBmeta - apiMeta", apiMeta);

        if (apiMeta.osobies) { // OSOBY - sprawdzam co jest DODANE po ostatniej aktualizacji w API:
                await db.osoby.where('a').above(apiMeta.osobies.aktualizacja).toArray().then(data => {
                        zakres.doWyslania += data.length;
                        zakres.osobies = data;
                        // console.log("getIDBmeta - o", data.length, data);
                }).catch(e=>{
                        console.log("error db.osoby.where:",e);
                });
        }
        if (apiMeta.noclegis) { // NOCLEGI - sprawdzam co jest DODANE po ostatniej aktualizacji w API:
                await db.noclegi.where('a').above(apiMeta.noclegis.aktualizacja).toArray().then(data => {
                        zakres.doWyslania += data.length;
                        zakres.noclegis = data;
                        // console.log("getIDBmeta - n", data.length, data);
                }).catch(e=>{
                        console.log("error db.noclegi.where:",e);
                });
        }
        if (apiMeta.przychodies) { // NOCLEGI - sprawdzam co jest DODANE po ostatniej aktualizacji w API:
                await db.przychody.where('a').above(apiMeta.przychodies.aktualizacja).toArray().then(data => {
                        zakres.doWyslania += data.length;
                        zakres.przychodies = data;
                        // console.log("getIDBmeta - n", data.length, data);
                }).catch(e=>{
                        console.log("error db.przychody.where:",e);
                });
        }
        if (apiMeta.wydatkis) { // NOCLEGI - sprawdzam co jest DODANE po ostatniej aktualizacji w API:
                await db.wydatki.where('a').above(apiMeta.wydatkis.aktualizacja).toArray().then(data => {
                        zakres.doWyslania += data.length;
                        zakres.wydatkis = data;
                        // console.log("getIDBmeta - n", data.length, data);
                }).catch(e=>{
                        console.log("error db.wydatki.where:",e);
                });
        }
        if (apiMeta.odwiedzinies) { // NOCLEGI - sprawdzam co jest DODANE po ostatniej aktualizacji w API:
                await db.odwiedziny.where('a').above(apiMeta.odwiedzinies.aktualizacja).toArray().then(data => {
                        zakres.doWyslania += data.length;
                        zakres.odwiedzinies = data;
                        // console.log("getIDBmeta - n", data.length, data);
                }).catch(e=>{
                        console.log("error db.wydatki.where:",e);
                });
        }
        // console.log("zakres",zakres);
        return zakres;
}

// GET - pobranie porcji danych API z ostatnich kilku miesięcy:
export async function getAPImiesiecy(miesiecy=12) {

        let wynik = {};
        let moment = dayjs().subtract(miesiecy, 'month').format(); // ustawiamy zakres czasu na 6 miesięcy od dzisiaj
        for await (let temp of tabeleAPI.map( async source => { // konstrukt for of pozwala na zwrócenie obietnicy jako efektu pętli

                let url = "/" + source;
                if ( miesiecy===12 ) {
                        url = url + ( ( source!=="osobies" && source!=="inwentarzs" ) ? ("?aktualizacja[after]=" + encodeURIComponent(moment)) : '');
                } else {
                        url = url + "?aktualizacja[after]=" + encodeURIComponent(moment);
                }

                await getAPIlist(url) // pobranie porcji danych z API
                        .then(data => putIDB(data['hydra:member'])) // i wgranie ich do IDB
                        .then(e=>{
                                wynik[source] = e;
                                console.log("then putIDB - zapisanych:",source,e)
                        })
                        .catch( e => {
                                console.log("error getAPIlist in getAPIall:",e);
                        }); 
        }))
        {wynik.smietnik = temp} // ta linia jest tylko po to, żeby ukryć warning o braku użycia temp
        
        return wynik; // zwracamy liczby zapsanych rekordów w poszczególnych tabelach
}

// włożenie danych pobranych z API do IDB:
async function putIDB(hydraMember) {

        let zrealizowany = 0;
        // przekazywany jest zestaw rekordów, zatem dla każdego rekordu:
        for await (let temp of hydraMember.map( async rec => {
                // APIdata && APIdata.forEach(rec => {

                if (rec['@type'] === "Osoby") { // czynnosci jeśli tabela to Osoby

                        await db.osoby.get(rec.id).then( async rekordIDB => { // pobieram rekord z IDB
                                let rekordAPI = osobaBaza(rec);
                                // console.log(JSON.stringify(rekordIDB),JSON.stringify(rekordAPI));
                                if (rekordIDB) { // jeśli istnieje w lokalnej bazie rekord o id pobranej osoby to: 
                                        if (
                                                (rekordIDB.as < rekordAPI.as) 
                                                && !compare(osobaDiff(rekordIDB), osobaDiff(rekordAPI))
                                        ) { 
                                                console.log("idb o",JSON.stringify(rekordIDB));
                                                console.log("api o",JSON.stringify(rekordAPI));
                                                        // jeśli rekord zdalny i lokalny są różne - zawsze nadpisz zdalnym
                                                // ale kopię zachowaj w tabeli "robocze"
                                                let rekordBack = {};
                                                rekordBack.zmienna = "backup";
                                                rekordBack.typ = "osoby";
                                                rekordBack.value = rekordIDB;
                                                await db.roboczy.add(rekordBack);
                                                await db.osoby.put(rekordAPI).then(() => {
                                                        zrealizowany += 1;
                                                        // console.log("sukces db.osoby.put:", zrealizowany);
                                                }).catch(e => {
                                                        console.log("error db.osoby.put:", e);
                                                });
                                        }
                                } else {
                                        // jeśli zaś nie istnieje lokalnie pobrana rekord to ją po prostu dodaj:
                                        // console.log("dodana rekord", rekordAPI);
                                        await db.osoby.add(rekordAPI).then(() => {
                                                zrealizowany += 1;
                                                // console.log("sukces db.osoby.add:", zrealizowany);
                                        }).catch(e => {
                                                console.log("error db.osoby.add:", e);
                                        });
                                }
                        });
                        // return zrealizowany;
                }

                if (rec['@type'] === "Noclegi") { // czynnosci jeśli tabela to Noclegi

                        await db.noclegi.get(rec.id).then( async rekordIDB => { // pobieram rekord z IDB
                                let rekordAPI = noclegBaza(rec);
                                // console.log(JSON.stringify(rekordIDB),JSON.stringify(rekordAPI));
                                if (rekordIDB) { // jeśli istnieje w lokalnej bazie rekord o id pobranej noclegi to: 
                                        if (
                                                (rekordIDB.as < rekordAPI.as) 
                                                && !compare(noclegDiff(rekordIDB), noclegDiff(rekordAPI))
                                        ) { 
                                                // console.log("idb n",JSON.stringify(rekordIDB));
                                                // console.log("api n",JSON.stringify(rekordAPI));
                                                // jeśli rekord zdalny i lokalny są różne - zawsze nadpisz zdalnym
                                                // ale kopię zachowaj w tabeli "robocze"
                                                let rekordBack = {};
                                                rekordBack.zmienna = "backup";
                                                rekordBack.typ = "noclegi";
                                                rekordBack.value = rekordIDB;
                                                await db.roboczy.add(rekordBack);
                                                await db.noclegi.put(rekordAPI).then(() => {
                                                        zrealizowany += 1;
                                                        // console.log("sukces db.nocl.put:", zrealizowany);
                                                }).catch(e => {
                                                        console.log("error db.noclegi.put:", e);
                                                });
                                        }
                                } else {
                                        // jeśli zaś nie istnieje lokalnie pobrana rekord to ją po prostu dodaj:
                                        // console.log("dodana rekord", rekordAPI);
                                        await db.noclegi.add(rekordAPI).then(() => {
                                                zrealizowany += 1;
                                                // console.log("sukces db.nocl.add:", zrealizowany);
                                        }).catch(e => {
                                                console.log("error db.noclegi.add:", e);
                                        });
                                }
                        });
                        // return zrealizowany;
                }

                if (rec['@type'] === "Przychody") { // czynnosci jeśli tabela to Przychody

                        await db.przychody.get(rec.id).then( async rekordIDB => { // pobieram rekord z IDB
                                let rekordAPI = przychodBaza(rec);
                                // console.log(JSON.stringify(rekordIDB),JSON.stringify(rekordAPI));
                                if (rekordIDB) { // jeśli istnieje w lokalnej bazie rekord o id pobranej przychody to: 
                                        if (
                                                (rekordIDB.as < rekordAPI.as) 
                                                && !compare(przychodDiff(rekordIDB), przychodDiff(rekordAPI))
                                        ) { 
                                                // jeśli rekord zdalny i lokalny są różne - zawsze nadpisz zdalnym
                                                // ale kopię zachowaj w tabeli "robocze"
                                                let rekordBack = {};
                                                rekordBack.zmienna = "backup";
                                                rekordBack.typ = "przychody";
                                                rekordBack.value = rekordIDB;
                                                await db.roboczy.add(rekordBack);
                                                await db.przychody.put(rekordAPI).then(() => {
                                                        zrealizowany += 1;
                                                }).catch(e => {
                                                        console.log("error db.przychody.put:", e);
                                                });
                                        }
                                } else {
                                        // jeśli zaś nie istnieje lokalnie pobrana rekord to ją po prostu dodaj:
                                        // console.log("dodana rekord", rekordAPI);
                                        await db.przychody.add(rekordAPI).then(() => {
                                                zrealizowany += 1;
                                        }).catch(e => {
                                                console.log("error db.przychody.add:", e);
                                        });
                                }
                        });
                        // return zrealizowany;
                }

                if (rec['@type'] === "Wydatki") { // czynnosci jeśli tabela to Wydatki

                        await db.wydatki.get(rec.id).then( async rekordIDB => { // pobieram rekord z IDB
                                let rekordAPI = wydatekBaza(rec);
                                // console.log(JSON.stringify(rekordIDB),JSON.stringify(rekordAPI));
                                if (rekordIDB) { // jeśli istnieje w lokalnej bazie rekord o id pobranej wydatki to: 
                                        if (
                                                (rekordIDB.as < rekordAPI.as) 
                                                && !compare(wydatekDiff(rekordIDB), wydatekDiff(rekordAPI))
                                        ) { 
                                                // jeśli rekord zdalny i lokalny są różne - zawsze nadpisz zdalnym
                                                // ale kopię zachowaj w tabeli "robocze"
                                                let rekordBack = {};
                                                rekordBack.zmienna = "backup";
                                                rekordBack.typ = "wydatki";
                                                rekordBack.value = rekordIDB;
                                                await db.roboczy.add(rekordBack);
                                                await db.wydatki.put(rekordAPI).then(() => {
                                                        zrealizowany += 1;
                                                }).catch(e => {
                                                        console.log("error db.wydatki.put:", e);
                                                });
                                        }
                                } else {
                                        // jeśli zaś nie istnieje lokalnie pobrana rekord to ją po prostu dodaj:
                                        // console.log("dodana rekord", rekordAPI);
                                        await db.wydatki.add(rekordAPI).then(() => {
                                                zrealizowany += 1;
                                        }).catch(e => {
                                                console.log("error db.wydatki.add:", e);
                                        });
                                }
                        });
                        // return zrealizowany;
                }

                if (rec['@type'] === "Odwiedziny") { // czynnosci jeśli tabela to Odwiedziny

                        await db.odwiedziny.get(rec.id).then( async rekordIDB => { // pobieram rekord z IDB
                                let rekordAPI = odwiedzinyBaza(rec);
                                // console.log(JSON.stringify(rekordIDB),JSON.stringify(rekordAPI));
                                if (rekordIDB) { // jeśli istnieje w lokalnej bazie rekord o id pobranej wydatki to: 
                                        if (
                                                (rekordIDB.as < rekordAPI.as) 
                                                && !compare(odwiedzinyDiff(rekordIDB), odwiedzinyDiff(rekordAPI))
                                        ) { 
                                                // jeśli rekord zdalny i lokalny są różne - zawsze nadpisz zdalnym
                                                // ale kopię zachowaj w tabeli "robocze"
                                                let rekordBack = {};
                                                rekordBack.zmienna = "backup";
                                                rekordBack.typ = "odwiedziny";
                                                rekordBack.value = rekordIDB;
                                                await db.roboczy.add(rekordBack);
                                                await db.odwiedziny.put(rekordAPI).then(() => {
                                                        zrealizowany += 1;
                                                }).catch(e => {
                                                        console.log("error db.wydatki.put:", e);
                                                });
                                        }
                                } else {
                                        // jeśli zaś nie istnieje lokalnie pobrana rekord to ją po prostu dodaj:
                                        // console.log("dodana rekord", rekordAPI);
                                        await db.odwiedziny.add(rekordAPI).then(() => {
                                                zrealizowany += 1;
                                        }).catch(e => {
                                                console.log("error db.wydatki.add:", e);
                                        });
                                }
                        });
                        // return zrealizowany;
                }

                if (rec['@type'] === "Inwentarz") { // czynnosci jeśli tabela to Inwentarz

                        let rekordAPI = inwentarzBaza(rec);
                        await db.inwentarz.put(rekordAPI).then(() => {
                                zrealizowany += 1;
                        }).catch(e => {
                                console.log("error db.inwentarz.add:", e);
                        });
                        // return zrealizowany;
                }

                if (rec['@type'] === "JoomlaUsers") { // czynnosci jeśli tabela to JoomlaUsers

                        let rekordAPI = jusersBaza(rec);
                        await db.jusers.put(rekordAPI).then(() => {
                                zrealizowany += 1;
                        }).catch(e => {
                                console.log("error db.jusers.add:", e);
                        });
                        // return zrealizowany;
                }
                // console.log("zrealizowany:", zrealizowany);
        })){if (temp) return zrealizowany}

        return zrealizowany;
}

// włożenie danych pobranych z API do IDB:
export async function putAPI(idbMeta,apiMeta) {
        console.log("apiMeta:",apiMeta);

        // zestaw rekordów bierzemy ze stanu, zatem dla każdego rekordu:
        if (idbMeta.osobies) {
                for await (let temp of idbMeta.osobies.map(rec => {
                        // - czy sprawdzać GET-em czy rekord istnieje? Do przemyślenia...
                        // - POST i PUT i FETCH wymagają tego samego kształtu obiektu
                        let id = rec.id;
                        let rekord = osobaAPI(rec);
                        // - jeśli data dodania nowsza niż last_akt w API to robię POST,
                        if (rec.d > apiMeta.osobies.aktualizacja) {
                                postAPIrec('osobies', rekord).then(e => {
                                        console.log("post osoba sukces:", e);
                                }).catch(e => {
                                        console.log("post osoba error", e);
                                });
                        } else { // - jeśli rekord w API juz stnieje to robię PUT
                                putAPIrec('osobies', id, rekord).then(e => {
                                        console.log("put osoba sukces:", e);
                                }).catch(e => {
                                        console.log("put osoba error", e);
                                });
                        }
                        return id;
                })){return temp};
        }
        if (idbMeta.noclegis) {
                for await (let temp of idbMeta.noclegis.map(rec => {
                        // - czy sprawdzać GET-em czy rekord istnieje? Do przemyślenia...
                        // - POST i PUT i FETCH wymagają tego samego kształtu obiektu
                        let id = rec.id;
                        let rekord = noclegAPI(rec);
                        // - jeśli data dodania nowsza niż last_akt w API to robię POST,
                        if (rec.d > apiMeta.noclegis.aktualizacja) {
                                postAPIrec('noclegis', rekord).then(e => {
                                        console.log("post nocleg sukces:", e);
                                }).catch(e => {
                                        console.log("post nocleg error", e);
                                });
                        } else { // - jeśli rekord w API juz stnieje to robię PUT
                                putAPIrec('noclegis', id, rekord).then(e => {
                                        console.log("put nocleg sukces:", e);
                                }).catch(e => {
                                        console.log("put nocleg error", e);
                                });
                        }
                        return id;
                })){return temp};
        }
        if (idbMeta.przychodies) {
                for await (let temp of idbMeta.przychodies.map(rec => {
                        // - czy sprawdzać GET-em czy rekord istnieje? Do przemyślenia...
                        // - POST i PUT i FETCH wymagają tego samego kształtu obiektu
                        let id = rec.id;
                        let rekord = przychodAPI(rec);
                        // - jeśli data dodania nowsza niż last_akt w API to robię POST,
                        if (rec.d > apiMeta.przychodies.aktualizacja) {
                                postAPIrec('przychodies', rekord).then(e => {
                                        console.log("post przychod sukces:", e);
                                }).catch(e => {
                                        console.log("post przychod error", e);
                                });
                        } else { // - jeśli rekord w API juz stnieje to robię PUT
                                putAPIrec('przychodies', id, rekord).then(e => {
                                        console.log("put przychod sukces:", e);
                                }).catch(e => {
                                        console.log("put przychod error", e);
                                });
                        }
                        return id;
                })){return temp};
        }
        if (idbMeta.wydatkis) {
                for await (let temp of idbMeta.wydatkis.map(rec => {
                        // - czy sprawdzać GET-em czy rekord istnieje? Do przemyślenia...
                        // - POST i PUT i FETCH wymagają tego samego kształtu obiektu
                        let id = rec.id;
                        let rekord = wydatekAPI(rec);
                        // - jeśli data dodania nowsza niż last_akt w API to robię POST,
                        if (rec.d > apiMeta.wydatkis.aktualizacja) {
                                postAPIrec('wydatkis', rekord).then(e => {
                                        console.log("post wydatek sukces:", e);
                                }).catch(e => {
                                        console.log("post wydatek error", e);
                                });
                        } else { // - jeśli rekord w API juz stnieje to robię PUT
                                putAPIrec('wydatkis', id, rekord).then(e => {
                                        console.log("put wydatek sukces:", e);
                                }).catch(e => {
                                        console.log("put wydatek error", e);
                                });
                        }
                        return id;
                })){return temp};
        }
        if (idbMeta.odwiedzinies) {
                for await (let temp of idbMeta.odwiedzinies.map(rec => {
                        // - czy sprawdzać GET-em czy rekord istnieje? Do przemyślenia...
                        // - POST i PUT i FETCH wymagają tego samego kształtu obiektu
                        let id = rec.id;
                        let rekord = odwiedzinyAPI(rec);
                        // - jeśli data dodania nowsza niż last_akt w API to robię POST,
                        if (rec.d > apiMeta.odwiedzinies.aktualizacja) {
                                postAPIrec('odwiedzinies', rekord).then(e => {
                                        console.log("post odwiedziny sukces:", e);
                                }).catch(e => {
                                        console.log("post odwiedziny error", e);
                                });
                        } else { // - jeśli rekord w API juz stnieje to robię PUT
                                putAPIrec('odwiedzinies', id, rekord).then(e => {
                                        console.log("put odwiedziny sukces:", e);
                                }).catch(e => {
                                        console.log("put odwiedziny error", e);
                                });
                        }
                        return id;
                })){return temp};
        }
}

// + rozpoznać listę rekordów do wysłania
// + wysłać 
// - jak ew. sprawdzić?

// + ekran rozwiązywania konfliktów
// + ekran instalacyjny
