import * as React from "react";
import { 
        AutocompleteInput, 
        FormDataConsumer, 
        SimpleForm, 
        Create, 
        Edit, 
        TextInput, 
        NumberInput, 
} from 'react-admin';
import { makeStyles, Typography } from "@material-ui/core";

import { kategorie_noclegu, noclegInit, noclegZapis, validateNocleg } from '../../obliczenia/nocleg';
import { OsobaInputField } from "../osoby/OsobaInputField";
import { OsobaInfo } from "../osoby/OsobaDane";
import DatyField from "../Daty";

import { NoclegDane } from "./NoclegDane";
import { NoclegiFormToolbar } from "./NoclegiFormToolbar";

const useStyles = makeStyles({
    input: {
            width: false
    },
});

export const NoclegiForm = props => {
        const classes = useStyles();
        // console.log("NocForm",props);
        window.scrollTo(0,0); // przewinięcie formularza do góry ekranu 
        return (
                <SimpleForm {...props}
                        toolbar={<NoclegiFormToolbar />}
                        initialValues={noclegInit}
                        validate={validateNocleg}
                        variant="outlined"
                        redirect="edit"
                        
                >
                        <Typography className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense">
                                {props.record.id && `Edycja noclegu nr: ${props.record.id}`}
                                {!props.record.id && `Dodawanie nowego noclegu`}
                        </Typography>

                        <OsobaInputField
                                source="osoba"
                                reference="osoby"
                                perPage={100000}
                                sort={{ field: 'nazwisko', order: 'ASC' }}
                                fullWidth
                        />

                        <FormDataConsumer>{({ formData, ...rest }) => (<>
                                {formData.osoba && <OsobaInfo o={formData.osoba} />}
                        </>)}</FormDataConsumer>

                        <DatyField
                                source="od"
                                label="Dzień przyjścia"
                                maxDateMessage="Halo! Data przyjścia musi być wcześniej niż jutro ;-)"
                                disableFuture
                        />

                        <DatyField
                                source="do"
                                label="Dzień wyjścia"
                                minDateMessage="Halo! Data wyjścia musi być później niż data przyjścia!"
                        />

                        <AutocompleteInput
                                source="kat"
                                label="Kategoria noclegu"
                                choices={kategorie_noclegu}
                                optionText="label"
                                optionValue="id"
                                helperText={false}
                        />
                        <FormDataConsumer>{({ formData, ...rest }) => (<>
                                {formData.kat === 7 && <React.Fragment>
                                        <NumberInput 
                                                source="o" 
                                                label="Opłata" 
                                                {...rest} 
                                                helperText={false} 
                                                initialValue={0} 
                                        /><br />
                                        <TextInput
                                                {...rest}
                                                source="info.uwagi"
                                                label="Uwagi"
                                                rows={3}
                                                multiline
                                                className={classes.input}
                                                fullWidth
                                        />
                                </React.Fragment>}
                        </>)}
                        </FormDataConsumer>

                        <NoclegDane />

                </SimpleForm>
)};

   




export const NoclegiEdit = props => (
    <Edit {...props} title=" " transform={(data) => noclegZapis(data)}>
            <NoclegiForm {...props} />
    </Edit>
);

export const NoclegiCreate = props => (
    <Create {...props} title=" " transform={(data) => noclegZapis(data)}>
            <NoclegiForm {...props} />
    </Create>
);
