import dayjs from 'dayjs'
import { clean } from 'fast-clean';

export const lista_gadzetow = [
    { id: '', label: '' },
    { id: 'koszulki', label: 'koszulka - 30 zł' },
    { id: 'torba', label: 'torba - 15 zł' },
    { id: 'mapa', label: 'mapa - 20 zł' },
    { id: 'TAO', label: 'tomik TAO - 25 zł' },
    { id: 'banieczka', label: 'banieczka z 40-lecia - 40 zł' },
    { id: 'skarbonka', label: 'skarbonka' },
    { id: 'pokaz astronomiczny', label: 'pokaz astronomiczny' },
];

// formatowanie obiektu do wyświetlenia na ekranie
export const przychodFormat = (props) => {
        
        let przychod = przychodInit();

        if (props.info && props.info.length===0) props.info = przychod.info; // jeśli info jest pustą tablicą to nadpisz domyślnymi wartościami
        // props = clean(props, {nullCleaner: true}); // wyczyszczenie starego formatu i pustych danych
        Object.entries(props).forEach(([k,v]) => { przychod[k] = v }); // przepisanie właściwości z propsa na przychod

        przychod.dataod_label = dayjs(props.od).format('YYYY-MM-DD');
        przychod.datado_label = dayjs(props.do).format('YYYY-MM-DD');
        przychod.nocy = dayjs(przychod.datado_label).diff(dayjs(przychod.dataod_label), 'days');

        przychod.kwota = 0;

        return przychod;
};

// transkrypcja rekordu z API na dane do zapisania w IDB
export const przychodBaza = (props) => {

        // let przychod = przychodInit;
        let przychod = przychodInit();

        przychod.id        = props.id;
        przychod.dzien     = dayjs(props.data).format('YYYY-MM-DD');
        przychod.gadzet    = props.gadzet;
        przychod.liczba    = props.liczba;
        przychod.kwota     = props.kwota;
        przychod.info      = props.info;
        przychod.d         = dayjs(props.dodane).format();
        przychod.a         = dayjs(props.aktualizacja).format();
        przychod.as        = dayjs(props.aktualizacja).format();

        return przychod;
};

// zdefiniowanie co będzie zapisane w IDB:
export const przychodZapis = (props) => {

        // console.log("zapis - początek",props);

        // let przychod = przychodInit;
        let przychod = przychodInit();

        if (props.id>0) przychod.id = props.id;
        przychod.dzien     = dayjs(props.dzien).format('YYYY-MM-DD');
        przychod.gadzet    = props.gadzet;
        przychod.liczba    = props.liczba;
        przychod.kwota     = props.kwota;
        przychod.info      = props.info;
        przychod.d         = dayjs(props.d).format();
        przychod.a         = dayjs().format();

        // console.log("zapis - koniec",przychod); 

        return clean(przychod); // , {nullCleaner: true}
};

// obiekt do wysłania do API:
export const przychodAPI = (props) => {

        let przychod = {};

        przychod.id             = props.id;
        przychod.data           = dayjs(props.dzien).format('YYYY-MM-DD');
        przychod.gadzet         = props.gadzet;
        przychod.liczba         = props.liczba;
        przychod.kwota          = props.kwota;
        przychod.info           = JSON.stringify(props.info);
        przychod.dodane         = dayjs(props.d).format();
        przychod.aktualizacja   = dayjs(props.a).format();

        // właściwości może być mniej lub więcej - ważne by na końcu JSON-a nie było przecinka ;-), bo generuje błąd...
        return clean(przychod); 
};

// format informacji w rozwiązywaniu konfliktów:
export const przychodDiff = (props) => {

        let przychod = {};

        // if (props.id>0) nocleg.id = props.id;
        przychod.dzien          = dayjs(props.dzien).format('YYYY-MM-DD');
        przychod.gadzet         = props.gadzet;
        przychod.liczba         = props.liczba;
        przychod.kwota          = props.kwota;
        przychod.info           = props.info;
        przychod.dodane         = dayjs(props.d).format('YYYY-MM-DD HH:mm:ss');
        przychod.aktualizacja   = dayjs(props.a).format('YYYY-MM-DD HH:mm:ss');

        return clean(przychod); // , {nullCleaner: true}
};

// inicjalizacja obiektu
export function przychodInit() {
        let przychod = {
                dzien: dayjs().format('YYYY-MM-DD'),
                gadzet: null,
                liczba: null,
                kwota: null,
                info: {},
                d: dayjs().format(),
                a: dayjs().format()
        };
        return przychod;
}



// Walidacja przychodu:
export const validatePrzychod = (values) => {
        const errors = {};
        if (!values.od) {
                errors.od = 'Podaj dzień przyjścia';
        }
        //     if (!values.age) {
        //         // You can return translation keys
        //         errors.age = 'ra.validation.required';
        //     } else if (values.age < 18) {
        //         // Or an object if the translation messages need parameters
        //         errors.age = {
        //             message: 'ra.validation.minValue',
        //             args: { min: 18 }
        //         };
        //     }
        return errors
};




