import React from 'react';
import { useRefresh } from 'react-admin';
import { useFormState } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import SaveIcon from '@material-ui/icons/Save';
import HotelOutlinedIcon from '@material-ui/icons/HotelOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  exampleWrapper: {
    position: 'relative',
    marginTop: theme.spacing(3),
    height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  toolTip: {
          // wordBreak: "keep-all",
          whiteSpace: 'nowrap',
  },
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}));


export function NoclegiFormToolbarMobile(props) {

        const classes = useStyles();
        const { values } = useFormState();
        const [open, setOpen] = React.useState(false);
        const history = useHistory();
        const refresh = useRefresh();

        const handleClose = () => {
                setOpen(false);
        };

        const handleOpen = () => {
                setOpen(true);
        };

        // console.log("props-val",props,values);

        return (
          <React.Fragment>
            <Backdrop open={open} className={classes.root} />
            <SpeedDial
              ariaLabel="Noclegi"
              className={classes.speedDial}
              icon={<SpeedDialIcon 
                icon={<MoreVertOutlinedIcon  />} 
                openIcon={<MoreVertOutlinedIcon style={{transform: 'rotate(45deg)'}}/>} 
              />}
              onClose={handleClose}
              onOpen={handleOpen}
              open={open}
            >
                          <SpeedDialAction 
                          // akcja: zapisz dane formularza
                                  {...props}
                                  key="zapisz"
                                  icon={<SaveIcon 
                                        color="primary" 
                                        />}
                                  FabProps={{disabled: (!values.dotkniety ? values.dotkniety : props.pristine)}}
                                  tooltipOpen
                                  classes={{ staticTooltipLabel: classes.toolTip }}
                                  tooltipTitle="Zapisz"
                                  onClick={props.handleSubmit}
                          />
                          <SpeedDialAction
                          // zdubluj nocleg - nowa osoba
                                  key="dodaj"
                                  icon={<HotelOutlinedIcon />}
                                  FabProps={{
                                    disabled: (!props.pristine || !values.id),
                                    href: "/#/noclegi/create?source="+JSON.stringify({...values, osoba:undefined,id:undefined,d:undefined,a:undefined,as:undefined,dotkniety:undefined}),
                                  }}
                                  tooltipOpen
                                  classes={{ staticTooltipLabel: classes.toolTip }}
                                  tooltipTitle="Kopia noclegu z nową osobą"
                          />
                          <SpeedDialAction
                          // resetowanie formularza (wyłączone w odwiedzinach)
                                  key="nowy"
                                  icon={<AddOutlinedIcon color="primary" />}
                                  onClick={()=>{
                                    history.push("/noclegi",{record: {}});
                                    refresh();
                                  }}
                                  tooltipOpen
                                  classes={{ staticTooltipLabel: classes.toolTip }}
                                  tooltipTitle="Dodaj / resetuj"
                          />
        </SpeedDial>
    </React.Fragment>
  );
}
