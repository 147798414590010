import * as React from "react";
import { 
        SimpleForm, 
        Create, 
        Edit, 
} from 'react-admin';

import { osobaInit, osobaZapis, osobaFormat } from '../../obliczenia/osoba';
import { PeselDecode } from "../../obliczenia/pesel";
import { OsobaFormToolbar } from "./OsobaFormToolbar";
import OsobaFormFields from "./OsobaFormFields";



// Walidacja osoby:
const validateOsoba = (values) => {
        const errors = {};

        // console.log("values validate:",values);

        if (!values.imie) {
                errors.imie = 'Wpisz imię';
        }
        if (!values.nazwisko) {
                errors.nazwisko = 'Wpisz nazwisko';
        }
        if (!values.pesel) {
                errors.pesel = "Uzupełnij numer PESEL !"
        }
        if ( values.pesel && !(values.pesel.length === 11) ) {
                errors.pesel = "PESEL musi mieć 11 cyfr"
        }
        if ( values.pesel && values.pesel.length === 11 && !PeselDecode(values.pesel).valid ) {
                values.warn.pesel = 'PESEL jest niepoprawny, trzeba uzupełnić datę urodzenia i obywatelstwo:';
        } else { values.warn.pesel = ''; }

        if (values.warn.pesel && !values.info.hasOwnProperty('dataur') ) {
                errors.info.dataur = 'PESEL jest nieprawidłowy - wpisz datę urodzenia';
        } 

        if (values.warn.pesel && !values.p) {
                errors.p = 'PESEL jest nieprawidłowy - podaj państwo obywatelstwa';
        }

        return errors
};

const OsobaForm = props => {
        // console.log("OsForm",props)
        return (
        <SimpleForm {...props} 
                toolbar={<OsobaFormToolbar />} 
                initialValues={osobaInit} 
                validate={validateOsoba} 
                variant="outlined"
                >

                <OsobaFormFields {...props} />
        </SimpleForm>
)};


export const OsobaEdit = props => (
    <Edit {...props} transform={(data) => osobaZapis(osobaFormat(data))}>
            <OsobaForm {...props} />
    </Edit>
);

export const OsobaCreate = props => (
    <Create {...props} transform={(data) => osobaZapis(osobaFormat(data))}>
            <OsobaForm {...props} />
    </Create>
);
