import React from 'react';
import { AutocompleteInput, ReferenceInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center'
    }
});

// const spySubscription = { values: true }; // nie wiem do czego to potrzebne, ale (chyba) potrzebne...

const inputText = choice => { return choice.name+" - "+choice.username+" - "+choice.email; }; // wskazana / wybrana opcja

const matchSug = (filterValue, choice) => { // zdecydowanie które opcje spełniają podany tu warunek - bolean

//     console.log("matchsug",filterValue,choice);
    // if (!filterValue) return false;

        let sz = filterValue.split(" ");
        let szukam1 = new RegExp(sz[0], "i");
        let szukam2 = new RegExp(sz[1], "i");
        let szukam3 = new RegExp(sz[2], "i");
        let label = choice.name+" "+choice.username+" "+choice.email;

        return szukam1.test( label ) && szukam2.test( label ) && szukam3.test( label );
}

const OptionRenderer = choice => { // lista opcji spełniajacych warunek szukania
        
        // console.log("OptRen",choice);
        return (
                <span>
                        {choice.record.name} - {choice.record.username} - {choice.record.email}
                </span>
        );
};


export const OsobaInputFieldJU = props => {

    const classes = useStyles();
//     const { values } = useFormState({ subscription: spySubscription });
    
    return (
        <div className={classes.root}>
            <ReferenceInput
                key={0}
                // error="ala ma kotka"
                // warning="cela ma pieska"
                // filterToQuery={(e)=>e.nazwisko}
                // filterToQuery={()=>true}
                {...props} 
            >
                <AutocompleteInput 
                    label="Osoba"
                    variant="outlined"
                    suggestionLimit={8}
                    matchSuggestion={(filterValue, choice) => matchSug(filterValue, choice)}
                    inputText={inputText}
                    optionText={<OptionRenderer />}
                    resettable
                    limitChoicesToValue
                    helperText={false}
                //     onSelect={(a)=>{ if (a.name) { setTekst(a.name); setShowDialog(true); console.log(a); }}}
                />
            </ReferenceInput>
            <br/><br/><br/>
        </div>
    );
};
