import * as React from "react";
import { Toolbar } from 'react-admin';
import { useMediaQuery } from "@material-ui/core";
import { OdwiedzinyFormToolbarMobile } from "./OdwiedzinyFormToolbarMobile";
import { OdwiedzinyFormToolbarLarge } from "./OdwiedzinyFormToolbarLarge";

export const OdwiedzinyFormToolbar = props => {

        const isSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

        return (
          <Toolbar {...props} >
            { isSmall ? (
                <OdwiedzinyFormToolbarMobile {...props} />
            ) : (
                <OdwiedzinyFormToolbarLarge {...props} />
            )}
          </Toolbar>
        );
};
