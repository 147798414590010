import decodeJwt from 'jwt-decode';

const authProvider = {
    login: async ({ username, password }) =>  {
        let cred = JSON.stringify({ username, password });
        const request = new Request(process.env.REACT_APP_API_AUTH, {
            method: 'POST',
            body: cred,
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        try {
            const response = await fetch(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            const auth = await response.json();

            // let b = window.atob(auth.token);
            // console.log("zdekodowany c",b);
            // console.log("auth",auth);
            let enc = window.btoa(cred);
            localStorage.setItem('cred', enc);
            // localStorage.setItem('auth', JSON.stringify(auth));
            localStorage.setItem('token', auth.token);
            // let a = localStorage.getItem('auth');
        } catch (e) {
            throw new Error('Network error eeee');
        }
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject({ redirectTo: '/login' });
            // return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: () => localStorage.getItem('token')
        ? Promise.resolve()
        // : Promise.reject(),
        : Promise.reject({ redirectTo: '/login' }),
    logout: () => {
        localStorage.removeItem('token');
        return Promise.resolve('/login');
        // return Promise.resolve();
    },
    getIdentity: () => {
        try {
            const tok = decodeJwt(localStorage.getItem('token'));
            // console.log("token",tok);
            let id = tok.roles[0];
            let fullName = tok.username;
            let avatar = '';
            let exp = tok.exp;
            // const { id, fullName, avatar } = JSON.parse(localStorage.getItem('auth'));
            return Promise.resolve({ id, fullName, avatar, exp });
        } catch (error) {
            return Promise.reject(error);
        }
    },
    getPermissions: () => {
        const tok = decodeJwt(localStorage.getItem('token'));
        const role = tok.roles;
        // Role w aplikacji określają dwie litery:
        // pierwsza - zasoby aplikacji:
        // n - noclegi
        // o - osoby
        // w - wydatki
        // p - przychody
        // z - zapowiedzi
        // i - inwentarz
        // 
        // druga - uprawnienia do zasobu:
        // e - edycja, tworzenie i lista
        // l - lista tylko
        // w - własne tylko (edycja, tworzenie, lista)
        let perms = [];
        if (role.includes('ROLE_USER')) { perms = ['nw','ow','zw']; }
        // if (role.includes('ROLE_ADMIN')) { perms = ['ne','oe','we','pe','ze','ie']; }
        if (role.includes('ROLE_GOSPODARZ')) { perms = ['ne','oe','wl','pl','ze','il']; }
        // if (role.includes('ROLE_USER')) { perms = ['nw','ow','zw']; }

        // const role = localStorage.getItem('permissions');
        return perms ? Promise.resolve(perms) : Promise.reject();
    }

};
    
export default authProvider;

