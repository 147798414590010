import * as React from 'react';
import { 
    AppBar, 
    // LoadingIndicator,
    // RefreshButton, 
    // Toolbar, 
    // UserMenu, 
    // MenuItemLink, 
    // UserMenu 
} from 'react-admin';
// import Typography from '@material-ui/core/Typography';

// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import LegendPop from '../komponenty/LegendPop';
import { Typography } from '@material-ui/core';
// import { IconButton, Tooltip, useMediaQuery } from '@material-ui/core';
// import SettingsIcon from '@material-ui/icons/Settings';
// import Logo from './Logo';


const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

// const useStyles = makeStyles(
// );

const MyAppBar = props => {
    const classes = useStyles();
    
    
    // console.log("myappbar",props);
    return (
        <AppBar {...props}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            {/* <Logo /> */}
            <span className={classes.spacer} />
            {/* <ToggleThemeButton /> */}
            <LegendPop />
        </AppBar>
    );
};

export default MyAppBar;
