import * as React from "react";
import { AppBar, Tab, Tabs, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Link, Route, Switch } from 'react-router-dom';

import Instalacja from "./Instalacja";
import SynchroInfo from "./SynchroInfo";
import KonfliktyLista from "./SynchronizacjaKonfliktyLista";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(2),
  },
}));

const Synchronizacja = (props) => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          variant="fullWidth"
          aria-label="nav tabs example"
          className="MuiAppBar-colorPrimary"
        >
          <Tab label="Synchro" component={Link} to="/roboczy/synchronizacja" />
          <Tab label="Decyzje" component={Link} to="/roboczy/decyzje" />
          <Tab label="Instalacja" component={Link} to="/roboczy/instalacja" />
        </Tabs>
      </AppBar>

      <Switch>
        <Route path="/roboczy/synchronizacja">
          <Typography className={classes.root}>
            <SynchroInfo />
          </Typography>
        </Route>
        <Route path="/roboczy/decyzje">
          <Typography className={classes.root}>
            <KonfliktyLista {...props} />
          </Typography>
        </Route>
        <Route path="/roboczy/instalacja">
          <Typography className={classes.root}>
            <Instalacja />
          </Typography>
        </Route>
      </Switch>


    </div>
  );
}

export default Synchronizacja;
