import * as React from "react";
import { 
        SaveButton, 
        Link,
        CreateButton,
        useRedirect
} from 'react-admin';
import { useFormState } from 'react-final-form';
import Button from '@material-ui/core/Button';
import { Grid, Tooltip, Typography } from "@material-ui/core";

import HotelOutlinedIcon from '@material-ui/icons/HotelOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';

export const OsobaFormToolbarLarge = props => {
        const { values } = useFormState();
        const redirect = useRedirect();

        // console.log("FTL props-values",props,values);
        // const doNoclegu = (basePath, id, data) => { console.log("base, id, data",basePath,id,data); return `/noclegi/create`; };
        const onSuccess = ({ data }) => {
          let nocleg = {
            od: values.nocleg.od,
            do: values.nocleg.do,
            osoba: data.id,
            info: values.nocleg.info
          }
          redirect(`/noclegi/create?source=${JSON.stringify(nocleg)}`);
        };

        return (
            <Grid container spacing={3}>
              <Grid item xs>
                <Typography align="left">
                  
                  { props.record.nocleg && <SaveButton
                    {...props}
                    label="Zapisz => nocleg"
                    onSuccess={onSuccess}
                    // redirect={doNoclegu}
                  /> 
                  }

                  
                  
                  <SaveButton
                    {...props}
                    label="Zapisz"
                    disabled={!values.dotkniety ? values.dotkniety : props.pristine } // dotknięcie jest w FormToolbar.js
                    // disabled={props.pristine}
                  />
                </Typography>
              </Grid>
              <Grid item xs>
              <Tooltip 
                    title="Dodaj taki sam nocleg z kolejną osobą" 
                    placement="top-end" 
                    arrow
                    disableFocusListener
                    disableTouchListener
                    // open={true}
                    >
              <Typography align="center">
                  { props.resource==="noclegi" && <Button
                    variant="outlined"
                    color="primary"
                    component={Link}
                    to={{
                      pathname: "/noclegi/create",
                      state: { record: { ...values, osoba: null, id: "" } },
                    }}
                    disabled={ !props.pristine || !values.id }
                  >
                    <AddOutlinedIcon /> &nbsp; <HotelOutlinedIcon />
                  </Button> }
                  
                </Typography>
                </Tooltip>









                { props.resource==="odwiedziny" && <Tooltip 
                ///////////////////////////////////////////////////////////////////////////////////////
                // przysko dodawania noclegu ze zgłoszenia - widoczny tylko w zgłoszeniu
                    title="Dodaj nocleg utworzony z tej rezerwacji" 
                    placement="top-end" 
                    arrow
                    disableFocusListener
                    disableTouchListener
                    // open={true}
                    >
              <Typography align="center">

                  { values.kto_info && !values.kto_info.nocleg.osoba && <Button
                    variant="outlined"
                    color="primary"
                    component={Link}
                    to={{
                      pathname: "/osoby/create",
                      state: { record: { ...values.kto_info, dubju: [values.kto], id: "" } },
                    }}
                  >
                    <AddOutlinedIcon /> &nbsp; <HotelOutlinedIcon />
                  </Button> 
                  }
                  
                  { values.kto_info && values.kto_info.nocleg.osoba && <Button
                  ////////////////////////////////////////////////////////////////////////////////////
                    variant="outlined"
                    color="primary"
                    component={Link}
                    to={{
                      pathname: "/noclegi/create",
                      state: { record: { ...values.kto_info.nocleg, id: "" } },
                    }}
                  >
                    <AddOutlinedIcon /> &nbsp; <HotelOutlinedIcon />
                  </Button> }

                </Typography>
                </Tooltip>
                }




              </Grid>
              <Grid item xs>
                <Typography align="right">
                  { values.id && props.resource!=="odwiedziny" && <CreateButton 
                        label="Nowy"
                        variant="outlined"
                        color="primary"
                        size="medium"
                  /> }
                </Typography>
              </Grid>
            </Grid>
        );
};
