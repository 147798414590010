
import * as React from "react";
import { List, Datagrid, TextField, Filter, SearchInput, Show, SimpleList } from 'react-admin';
import { Grid, makeStyles, useMediaQuery } from "@material-ui/core";
import { OsobaSumaLista } from "./OsobaDane";

const OsobyFilter = (props) => (
    <Filter {...props} >
        <SearchInput placeholder="szukanie po nazwisku" source="nazwisko" alwaysOn />
    </Filter>
);


const useStyles = makeStyles((theme) => ({
    root: {
            padding: theme.spacing(2),
    },
    right: {
            [theme.breakpoints.up('sm')]: {
                    textAlign: 'right',
            },
    },
}));

const OsobaShow = props => {
  
    const classes = useStyles();
    
    return (
      <Show
        {...props}
        title=" "
      >
                    <Grid container spacing={3} className={classes.root}>
                            <Grid item xs={12} sm={6}>
                                Tutaj będą różne informacje o osobie, oprócz obecnych także np. lista noclegów itp.
                                <OsobaSumaLista osoba={props.record} />
                            </Grid>
                    </Grid>
      </Show>
    );}


export const OsobyLista = props => {
    // console.log(props);
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List
            {...props}
            title={props.options.label}
            filters={<OsobyFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            bulkActionButtons={false}
            perPage={25}
            exporter={false}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => `${record.id}. ${record.imie} ${record.nazwisko}`}
                    secondaryText={record => `PESEL ${record.pesel}`}
                    // tertiaryText={record => new Date(record.published_at).toLocaleDateString()}
                    linkType={record => record.canEdit ? "edit" : "show"}
                />
            ) : (
                <Datagrid rowClick="edit" expand={<OsobaShow />}>
                    <TextField source="id" />
                    <TextField source="imie" />
                    <TextField source="nazwisko" />
                    <TextField source="pesel" />
                </Datagrid>
            )}
        </List>
    );
}
