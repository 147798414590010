import * as React from "react";
import { makeStyles } from "@material-ui/core";
import { useFormState } from 'react-final-form';
import { RadioButtonGroupInput } from 'react-admin';

import { db } from "../../security/dataProviderDB";
import { OsobaInputField } from "../osoby/OsobaInputField";

const useStyles = makeStyles({
  root: {
                width: '100%'
        }
}, { name: 'MuiFormControlLabel' });


const OdwiedzinyOsobyPolaczone = props => {

        const classes = useStyles();
        const { values } = useFormState();
        const [odwOs, setOdwOs] = React.useState(); // wszystkie ids Osoby jakie mają być wyświetlone pod formularzem
        const [odwOsChecked, setOdwOsChecked] = React.useState(); // ids JoomlaUsers przypisane (checked)

        React.useEffect(() => {
                let nowa = [{id:null,label:'dodaj użytkownika jako nową osobę'}]
                db.duble.get({typ:"u",idos:props.record.kto})
                .then(wiersz=>{ // pobieram informacje o połączeniach przypisanych do bieżącej osoby
                        let target = wiersz ? wiersz.target : null;
                        setOdwOsChecked(target); // ustaw przypisane pozycje
                        db.osoby.filter(o => { // szukam w bazie userów www osób o tym samym nazwisku co edytowana osoba
                                return (( 
                                        o.imie === values.kto_info.imie 
                                        && o.nazwisko === values.kto_info.nazwisko 
                                        ) 
                                        || o.id === target
                                        ) // elastyczność filtru do dopracowania 
                        }).toArray().then( wynik => {
                                let wynik2 = nowa.concat(wynik);
                                setOdwOs(wynik2);
                        });
                });

// metody czyszczenia tablic:
// let wynik = duJu.concat(e);
// let wynik2 = wynik.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i); // funkcja usuwająca duplikaty
// let wynik3 = wynik2.filter(w=>w.id!==props.record.id); // filtr usuwający bieżacą osobę

        // poniższy komentarz wyłącza warning o braku zależności w React.useEffect
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[]);

        // console.log("OsDuble - props,values:",props,values);
        return (
                <React.Fragment>
                        Żeby utorzyć nocleg wskaż Osobę lub utwórz nową.
                        { odwOs && !values.odwOsoPol && <React.Fragment>
                                { odwOs.length>1 ? ` Rozpoznane osoby:` : ` Automat nie rozpoznał osób, więc:` }<br/>
                                <RadioButtonGroupInput
                                        label=""
                                        source="odwOsPol"
                                        choices={odwOs} 
                                        defaultValue={odwOsChecked}
                                        optionText={o=>{ return <React.Fragment>
                                                { o.label ? o.label : <React.Fragment>
                                                        osobaID: {o.id}. {o.imie} {o.nazwisko} {o.pesel ? '- PESEL: '+o.pesel : ''}
                                                        </React.Fragment>}
                                        </React.Fragment>}}
                                        helperText={false}
                                        className={classes.root}
                                        // fullWidth
                                />
                        </React.Fragment>
                        }

                        { values.odwOsoPol ? <p>wskazana osoba:</p> : <p>...lub wyszukaj i wskaż osobę:</p> }

                        <OsobaInputField
                                source="odwOsoPol"
                                label="Gość Chaty"
                                reference="osoby"
                                perPage={100000}
                                sort={{ field: 'nazwisko', order: 'ASC' }}
                                fullWidth
                        />

                </React.Fragment>
        );

}

export default OdwiedzinyOsobyPolaczone;
