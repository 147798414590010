import dayjs from 'dayjs'
import { clean } from 'fast-clean';

export const typy_operacji = [
    { id: 0, label: 'wydatek zwykły / spłata' },
    { id: 1, label: 'do zapłacenia później' },
    { id: 2, label: 'do zapłacenia przelewem' },
];

// formatowanie obiektu do wyświetlenia na ekranie
export const wydatekFormat = (props) => {
        
        // dayjs.extend(utc)

        let wydatek = wydatekInit();

        if (props.info && props.info.length===0) props.info = wydatek.info; // jeśli info jest pustą tablicą to nadpisz domyślnymi wartościami
        // props = clean(props, {nullCleaner: true}); // wyczyszczenie starego formatu i pustych danych
        Object.entries(props).forEach(([k,v]) => { wydatek[k] = v }); // przepisanie właściwości z propsa na wydatek

        return wydatek;
};

// transkrypcja rekordu z API na dane do zapisania w IDB
export const wydatekBaza = (props) => {

        // let wydatek = wydatekInit;
        let wydatek = wydatekInit();

        wydatek.id              = props.id;
        wydatek.dzien           = dayjs(props.data).format('YYYY-MM-DD');
        wydatek.tytul           = props.tytul;
        wydatek.kwota           = props.kwota;
        wydatek.dlug            = props.zobowiazanie;
        wydatek.wykonawca       = props.wykonawca;
        wydatek.wierzyciel      = props.wierzyciel;
        wydatek.splacone        = props.splaconeprzez;
        wydatek.info            = props.info;
        wydatek.d               = dayjs(props.dodane).format();
        wydatek.a               = dayjs(props.aktualizacja).format();
        wydatek.as              = dayjs(props.aktualizacja).format();

        return wydatek;
};

// zdefiniowanie co będzie zapisane w IDB:
export const wydatekZapis = (props) => {

        // console.log("zapis - początek",props);

        let wydatek = wydatekInit();

        if (props.id>0) wydatek.id = props.id;
        wydatek.dzien           = dayjs(props.dzien).format('YYYY-MM-DD');
        wydatek.tytul           = props.tytul;
        wydatek.kwota           = props.kwota;
        wydatek.dlug            = props.dlug;
        wydatek.wykonawca       = props.wykonawca;
        wydatek.wierzyciel      = props.wierzyciel;
        wydatek.splacone        = props.splacone;
        wydatek.info            = props.info;
        wydatek.d               = dayjs(props.d).format();
        wydatek.a               = dayjs().format();

        // console.log("zapis - koniec",wydatek); 

        return clean(wydatek); // , {nullCleaner: true}
};

// obiekt do wysłania do API:
export const wydatekAPI = (props) => {

        let wydatek = {};

        wydatek.id              = props.id;
        wydatek.data            = dayjs(props.dzien).format('YYYY-MM-DD');
        wydatek.tytul           = props.tytul;
        wydatek.kwota           = props.kwota;
        wydatek.zobowiazanie    = props.dlug;
        wydatek.wykonawca       = props.wykonawca;
        wydatek.wierzyciel      = props.wierzyciel;
        wydatek.splaconeprzez   = props.splacone;
        wydatek.info            = JSON.stringify(props.info);
        wydatek.dodane          = dayjs(props.d).format();
        wydatek.aktualizacja    = dayjs(props.a).format();

        // właściwości może być mniej lub więcej - ważne by na końcu JSON-a nie było przecinka ;-), bo generuje błąd...
        return clean(wydatek); 
};

// format informacji w rozwiązywaniu konfliktów:
export const wydatekDiff = (props) => {

        let wydatek = {};

        // if (props.id>0) nocleg.id = props.id;
        wydatek.id              = props.id;
        wydatek.dzien           = dayjs(props.dzien).format('YYYY-MM-DD');
        wydatek.tytul           = props.tytul;
        wydatek.kwota           = props.kwota;
        wydatek.dlug            = props.dlug;
        wydatek.wykonawca       = props.wykonawca;
        wydatek.wierzyciel      = props.wierzyciel;
        wydatek.splacone        = props.splacone;
        wydatek.info            = props.info;
        wydatek.dodane          = dayjs(props.d).format();
        wydatek.aktualizacja    = dayjs(props.a).format();

        return clean(wydatek); // , {nullCleaner: true}
};

// inicjalizacja obiektu
export function wydatekInit() {
        let wydatek = {
                dzien: dayjs().format('YYYY-MM-DD'),
                tytul: '',
                kwota: null,
                dlug: 0,
                wykonawca: 3315,
                wierzyciel: null,
                splacone: null,
                info: {},
                d: dayjs().format(),
                a: dayjs().format()
        };
        return wydatek;
}










// Walidacja wydatku:
export const validateWydatek = (values) => {
        const errors = {};
        if (!values.dzien) {
                errors.dzien = 'Podaj dzień przyjścia';
        }
        //     if (!values.age) {
        //         // You can return translation keys
        //         errors.age = 'ra.validation.required';
        //     } else if (values.age < 18) {
        //         // Or an object if the translation messages need parameters
        //         errors.age = {
        //             message: 'ra.validation.minValue',
        //             args: { min: 18 }
        //         };
        //     }
        return errors
};







