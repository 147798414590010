import dayjs from 'dayjs'
import { clean } from 'fast-clean';

export const statusy_zgloszenia = [
        { id: 0, label: 'oczekujące' },
        { id: 1, label: 'zaakceptowane' },
        { id: 2, label: 'anulowane' },
        { id: 3, label: 'do kontaktu' },
        { id: -2, label: 'usunięte' },
];

export const chata_czy_poza = [
        { id: 0, label: 'nieokreślone' },
        { id: 1, label: 'nocleg w Chacie' },
        { id: 2, label: 'nocleg poza Chatą' },
];


// formatowanie obiektu do wyświetlenia na ekranie
export const odwiedzinyFormat = (props) => {
        
        // let odwiedziny = props;

        // if (props.info && props.info.length===0) props.info = odwiedziny.info; // jeśli info jest pustą tablicą to nadpisz domyślnymi wartościami
        // props = clean(props, {nullCleaner: true}); // wyczyszczenie starego formatu i pustych danych
        // Object.entries(props).forEach(([k,v]) => { props[k] = v }); // przepisanie właściwości z propsa na odwiedziny

        props.odn = dayjs(props.od).format("YYYY-MM-DD");
        props.don = dayjs(props.do).format("YYYY-MM-DD");

        if (props.state===0) props.state_label = "oczekujace";
        if (props.state===1) props.state_label = "zaakceptowane";
        if (props.state===2) props.state_label = "anulowane";
        if (props.state===-2) props.state_label = "usunięte";

        
        if (props.nch===0) props.nch_label = "nieokreślone";
        if (props.nch===1) props.nch_label = "wskazana Chata";
        if (props.nch===2) props.nch_label = "wskazany namiot";
                
        return props;
};

// transkrypcja rekordu z API na dane do zapisania w IDB
export const odwiedzinyBaza = (props) => {

        // let odwiedziny = odwiedzinyInit;
        let odwiedziny = {};

        odwiedziny.id           = props.id;
        odwiedziny.state        = props.state;
        odwiedziny.kto          = props.createdBy;
        odwiedziny.mod          = props.modifiedBy;
        odwiedziny.nazwa        = props.nazwa;
        odwiedziny.od           = dayjs(props.od).format('YYYY-MM-DD');
        odwiedziny.do           = dayjs(props.do).format('YYYY-MM-DD');
        odwiedziny.liczba       = props.liczba;
        odwiedziny.info         = props.info;
        odwiedziny.adm          = props.administracyjne;
        odwiedziny.nch          = props.noclegchata;
        odwiedziny.d            = dayjs(props.dodane).format();
        odwiedziny.a            = dayjs(props.aktualizacja).format();
        odwiedziny.as           = dayjs(props.aktualizacja).format();

        return odwiedziny;
};

// zdefiniowanie co będzie zapisane w IDB:
export const odwiedzinyZapis = (props) => {

        // console.log("zapis - początek",props);

        // let odwiedziny = odwiedzinyInit;
        let odwiedziny = {};

        if (props.id>0) odwiedziny.id = props.id;
        odwiedziny.state        = props.state;
        odwiedziny.kto          = props.kto;
        odwiedziny.mod          = props.zalogowany;
        odwiedziny.nazwa        = props.nazwa;
        odwiedziny.od           = dayjs(props.od).format('YYYY-MM-DD');
        odwiedziny.do           = dayjs(props.do).format('YYYY-MM-DD');
        odwiedziny.liczba       = props.liczba;
        odwiedziny.info         = props.info;
        odwiedziny.adm          = props.adm;
        odwiedziny.nch          = props.nch;
        odwiedziny.d            = dayjs(props.d).format();
        odwiedziny.a            = dayjs().format();
        // console.log("zapis - koniec",odwiedziny); 

        return clean(odwiedziny); // , {nullCleaner: true}
};

// obiekt do wysłania do API:
export const odwiedzinyAPI = (props) => {

        let odwiedziny = {};

        odwiedziny.id                   = props.id;
        odwiedziny.state                = props.state;
        // odwiedziny.createdBy            = props.kto;
        odwiedziny.modifiedBy           = props.mod;
        odwiedziny.nazwa                = props.nazwa;
        odwiedziny.od                   = dayjs(props.od).format('YYYY-MM-DD');
        odwiedziny.do                   = dayjs(props.do).format('YYYY-MM-DD');
        odwiedziny.liczba               = props.liczba;
        odwiedziny.info                 = props.info;
        odwiedziny.administracyjne      = props.adm;
        odwiedziny.noclegchata          = props.nch;
        // odwiedziny.duble                = props.duble;
        // odwiedziny.dodane               = dayjs(props.d).format();
        odwiedziny.aktualizacja         = dayjs(props.a).format();

        // właściwości może być mniej lub więcej - ważne by na końcu JSON-a nie było przecinka ;-), bo generuje błąd...
        return clean(odwiedziny); 
};

// format informacji w rozwiązywaniu konfliktów:
export const odwiedzinyDiff = (props) => {

        let odwiedziny = {};

        // if (props.id>0) nocleg.id = props.id;
        odwiedziny.state        = props.state;
        odwiedziny.kto          = props.kto;
        odwiedziny.modyfikowal  = props.mod;
        odwiedziny.nazwa        = props.nazwa;
        odwiedziny.od           = dayjs(props.od).format('YYYY-MM-DD');
        odwiedziny.do           = dayjs(props.do).format('YYYY-MM-DD');
        odwiedziny.liczba       = props.liczba;
        odwiedziny.info         = props.info;
        odwiedziny.admin        = props.adm;
        odwiedziny.noclegchata  = props.nch;
        odwiedziny.dodane       = dayjs(props.d).format('YYYY-MM-DD HH:mm:ss');
        odwiedziny.aktualizacja = dayjs(props.a).format('YYYY-MM-DD HH:mm:ss');

        return clean(odwiedziny); // , {nullCleaner: true}
};




