import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SaveIcon from '@material-ui/icons/Save';
import HotelOutlinedIcon from '@material-ui/icons/HotelOutlined';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import MenuOpenOutlinedIcon from '@material-ui/icons/MenuOpenOutlined';
import { OdwiedzinyNoclegDialog } from './OdwiedzinyNocleg';
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles((theme) => ({
  root: {
        height: '100vh',
        transform: 'translateZ(0px)',
        flexGrow: 1,
  },
  exampleWrapper: {
    position: 'relative',
    marginTop: theme.spacing(3),
    height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  toolTip: {
        //   wordBreak: "keep-all",
        //   backgroundColor: "#ccc",
          whiteSpace: 'nowrap',
  },
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}));


export function OdwiedzinyFormToolbarMobile(props) {

        const classes = useStyles();
        const [openTB, setOpenTB] = React.useState(false);
        const [openON, setOpenON] = React.useState(false);

        const handleClose = () => {
                setOpenTB(false);
        };

        const handleCloseON = () => {
                setOpenON(false);
        };

        const handleOpen = () => {
                setOpenTB(true);
        };

        const handleOpenON = () => {
                setOpenON(true);
        };
        // console.log("pris-val",props.pristine,values);

// import FingerprintOutlinedIcon from '@material-ui/icons/FingerprintOutlined';
// import SettingsEthernetOutlinedIcon from '@material-ui/icons/SettingsEthernetOutlined';
// import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
// import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
// import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
// import MenuOpenOutlinedIcon from '@material-ui/icons/MenuOpenOutlined';
// import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
        return (
                <React.Fragment>
                        <Backdrop open={openTB} className={classes.root} />
                        <SpeedDial
                                ariaLabel="Odwiedziny"
                                className={classes.speedDial}
                                icon={<SpeedDialIcon
                                        icon={<MoreVertOutlinedIcon />}
                                        openIcon={<MoreVertOutlinedIcon style={{ transform: 'rotate(45deg)' }} />}
                                />}
                                onClose={handleClose}
                                onOpen={handleOpen}
                                open={openTB}
                        >
                                <SpeedDialAction
                                        {...props}
                                        key="zapisz"
                                        icon={<SaveIcon
                                                color="primary"
                                        />}
                                        FabProps={{ disabled: props.pristine }}
                                        tooltipOpen
                                        classes={{ staticTooltipLabel: classes.toolTip }}
                                        tooltipTitle="Zapisz zmiany w zgłoszeniu"
                                        onClick={props.handleSubmit}
                                />
                                <SpeedDialAction
                                        key="nocleg"
                                        icon={<HotelOutlinedIcon />}
                                        tooltipTitle="Utwórz Nocleg ze Zgłoszenia"
                                        onClick={handleOpenON}
                                        tooltipOpen
                                        classes={{ staticTooltipLabel: classes.toolTip }}
                                />
                                <SpeedDialAction
                                        key="lista"
                                        icon={<MenuOpenOutlinedIcon />}
                                        FabProps={{ href: "/#/odwiedziny" }}
                                        tooltipOpen
                                        classes={{ staticTooltipLabel: classes.toolTip }}
                                        tooltipTitle="Wróć do listy zgłoszeń"
                                />
        </SpeedDial>
        <OdwiedzinyNoclegDialog open={openON} onClose={handleCloseON} {...props} />
    </React.Fragment>
  );
}
