import React from 'react';
import {
    Button,
    SaveButton,
    useCreate,
    useNotify,
    FormWithRedirect,
    Link,
    useUpdate
} from 'react-admin';

import { 
    Dialog, 
    DialogContent, 
    DialogActions 
} from '@material-ui/core';
import { useForm } from 'react-final-form';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import IconCancel from '@material-ui/icons/Cancel';

import { osobaFormat, osobaZapis } from '../../obliczenia/osoba';
import { PeselDecode } from '../../obliczenia/pesel';
import OsobaFormFields from './OsobaFormFields';

// Walidacja noclegu:
const validateOsoba = (values) => {
        const errors = {};

        // console.log("values validate:",values);

        if (!values.imie) {
                errors.imie = 'Wpisz imię';
        }
        if (!values.nazwisko) {
                errors.nazwisko = 'Wpisz nazwisko';
        }
        if (!values.pesel) {
                errors.pesel = "Uzupełnij numer PESEL !"
        }
        if ( values.pesel && !(values.pesel.length === 11) ) {
                errors.pesel = "PESEL musi mieć 11 cyfr"
        }
        if ( values.pesel && values.pesel.length === 11 && !PeselDecode(values.pesel).valid ) {
                values.warn.pesel = 'PESEL jest niepoprawny, trzeba uzupełnić datę urodzenia i obywatelstwo:';
        } else { values.warn.pesel = ''; }

        if (values.warn.pesel && !values.info.hasOwnProperty('dataur') ) {
                errors.info.dataur = 'PESEL jest nieprawidłowy - wpisz datę urodzenia';
        } 

        if (values.warn.pesel && !values.p) {
                errors.p = 'PESEL jest nieprawidłowy - podaj państwo obywatelstwa';
        }

        return errors
};

export function OsobaFormDialog({ 
  // onChange, 
  osoba,
  tekst,
  // show,
  showDialog, 
  setShowDialog 
}) {

    // const [showDialog, setShowDialog] = React.useState(show);
    const [create, { loading }] = useCreate('osoby');
    const [update] = useUpdate('osoby');
    const notify = useNotify();
    const form = useForm();
    // const o = osobaInit();
    const o = osobaFormat(osoba);

    // console.log("OsCre:",form);
        
    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async values => {
        let ido = values.id;
        if (!ido) {
        create(
            { payload: { data: osobaZapis(osobaFormat(values)) } },
            {
                onSuccess: ({ data }) => {
                    setShowDialog(false);
                    // Update the comment form to target the newly created post
                    // Updating the ReferenceInput value will force it to reload the available posts
                    form.change('osoba', data.id);
                    // onChange();
                },
                onFailure: ({ error }) => {
                    notify(error.message, 'error');
                }
            }
        );
          } else {
        update(
            { payload: { ido, data: osobaZapis(osobaFormat(values)) } }, 
            {
              onSuccess: ({ data }) => {
                  setShowDialog(false);
              },
              onFailure: ({ error }) => {
                  notify(error.message, 'error');
              }
          }
        );
          }
    };

    if(tekst) {
      let sz = tekst.split(" ");
      o.imie = sz[2];
      o.nazwisko = sz[1];
      o.pesel = sz[3];
      }



    return (
      <React.Fragment>
        { !o.id && <Button onClick={handleClick}><PersonAddOutlinedIcon fontSize="large" /></Button> }
        { o.id && <Link onClick={handleClick} >ID: {o.id}. {o.pesel && <span>PESEL: {o.pesel}, </span>}</Link> }
        <Dialog
          fullWidth
          open={showDialog}
          onClose={handleCloseClick}
          aria-label="Dodaj osobę"
        >
          <FormWithRedirect
            resource="osoby"
            initialValues={o} 
            validate={validateOsoba} 
            save={handleSubmit}
            render={({ handleSubmitWithRedirect, pristine, saving }) => (
              <>
                <DialogContent>
                    <OsobaFormFields record={o} />
                </DialogContent>
                <DialogActions>
                  <Button
                    label="zamknij"
                    onClick={handleCloseClick}
                    // disabled={loading}
                  >
                    <IconCancel />
                  </Button>
                  <SaveButton
                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                    pristine={pristine}
                    saving={saving}
                    disabled={loading}
                  />
                </DialogActions>
              </>
            )}
          />
        </Dialog>
      </React.Fragment>
    );
}
