import React from "react"; 
import polyglotI18nProvider from 'ra-i18n-polyglot';
import polishMessages from "./layout/customMessagePL";
// import polishMessages from 'ra-language-polish';
// import englishMessages from 'ra-language-english';
import { 
        Admin, 
        ListGuesser, 
        Resource,
        // ListGuesser, 
        // ShowGuesser 
} from "react-admin";
import { createMuiTheme } from "@material-ui/core";
import { teal } from "@material-ui/core/colors";
// import { Redirect } from 'react-router-dom';
// import { Router, Redirect, useHistory, useLocation } from 'react-router-dom';
// import { HydraAdmin, ResourceGuesser } from "@api-platform/admin";
import EmojiPeopleOutlinedIcon from '@material-ui/icons/EmojiPeopleOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import LocalHotelOutlinedIcon from '@material-ui/icons/LocalHotelOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import GavelOutlinedIcon from '@material-ui/icons/GavelOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

import MyAppLayout from "./layout/AppLayout";
import authProvider from "./security/authProvider";
import dataProviderDB from "./security/dataProviderDB";
import SprawdzeniePrzekierowanie from "./layout/SprawdzeniePrzekierowanie";

import Synchronizacja from "./komponenty/Synchronizacja";
import Noclegi from "./komponenty/noclegi/Noclegi";
import { OsobyLista } from "./komponenty/osoby/OsobyLista";
import { OsobaCreate, OsobaEdit } from "./komponenty/osoby/OsobaForm";
// import Wydatki from "./komponenty/wydatki/Wydatki";
// import Przychody from "./komponenty/przychody/Przychody";
import { OdwiedzinyEdit } from "./komponenty/odwiedziny/OdwiedzinyForm";
import Odwiedziny from "./komponenty/odwiedziny/Odwiedziny";
import { WydatkiLista } from "./komponenty/wydatki/WydatkiLista";
import { WydatkiCreate, WydatkiEdit } from "./komponenty/wydatki/WydatkiForm";
import { PrzychodyCreate, PrzychodyEdit } from "./komponenty/przychody/PrzychodyForm";
import { PrzychodyLista } from "./komponenty/przychody/PrzychodyLista";


const theme = createMuiTheme({
        palette: {
                primary: {
                        main: teal[700],
                },
                secondary: {
                        main: teal[900],
                },
        //     type: 'dark', // Switching the dark mode on is a single property value change.

},
});


// const messages = {
//         // fr: frenchMessages,
//         // en: englishMessages,
//         pl: polishMessages,
//     };
// const i18nProvider = polyglotI18nProvider(locale => messages[locale]);
const i18nProvider = polyglotI18nProvider(() => polishMessages, 'pl');
    
// teal
const App = () => {
        // const { permissions } = usePermissions();
        return (
                <React.Fragment>
                        <Admin
                                // dashboard={Start}
                                layout={MyAppLayout}
                                dataProvider={dataProviderDB}
                                authProvider={authProvider}
                                // customRoutes={customRoutes}
                                theme={theme}
                                // locale="pl" 
                                i18nProvider={i18nProvider}
                                disableTelemetry
                        >
                                {perm => [
                                // (console.log(perm)),
                                perm.includes('ze') ? <Resource name="odwiedziny" options={{ label: 'Zgłoszenia' }} icon={EmojiPeopleOutlinedIcon} list={Odwiedziny} edit={OdwiedzinyEdit} /> : null,
                                perm.includes('ne') ? <Resource name="noclegi" options={{ label: 'Noclegi' }} icon={LocalHotelOutlinedIcon} list={Noclegi} edit={Noclegi} create={Noclegi} /> : null,
                                perm.includes('oe') ? <Resource name="osoby" options={{ label: 'Osoby' }} icon={PeopleAltOutlinedIcon} list={OsobyLista} edit={OsobaEdit} create={OsobaCreate} /> : null,
                                perm.includes('we') ? <Resource name="wydatki" options={{ label: 'Wydatki' }} icon={ShoppingCartOutlinedIcon} list={WydatkiLista} edit={WydatkiEdit} create={WydatkiCreate} /> : null,
                                perm.includes('pe') ? <Resource name="przychody" options={{ label: 'Przychody' }} icon={AccountBalanceWalletOutlinedIcon} list={PrzychodyLista} edit={PrzychodyEdit} create={PrzychodyCreate} /> : null,
                                perm.includes('ie') ? <Resource name="inwentarz" options={{ label: 'Inwentarz' }} icon={GavelOutlinedIcon} list={ListGuesser} /> : null,
                                ( <Resource name="roboczy" options={{ label: 'Synchro' }} icon={SettingsOutlinedIcon} list={Synchronizacja} /> ),
                                ( <Resource name="jusers" /> ),
                                ( <Resource name="duble" /> ),
                                <SprawdzeniePrzekierowanie />,
                                ]}
                        </Admin>

                        {/* <HydraAdmin 
                        layout={MyAppLayout} 
                        dataProvider={ dataProvider }
                        authProvider={ authProvider }
                        entrypoint={ entrypoint }
                        >
				<ResourceGuesser resource={"osobies"} name={"osobies"} list={OsobyLista} />
        		</HydraAdmin> */}
                </React.Fragment>
        );
}
// }

export default App;
