import * as React from "react";
import { Toolbar } from 'react-admin';
import { useMediaQuery } from "@material-ui/core";
import PrzychodFormToolbarLarge from "./PrzychodFormToolbarLarge";
import PrzychodFormToolbarMobile from "./PrzychodFormToolbarMobile";

const PrzychodFormToolbar = props => {

        const isSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

        return (
          <Toolbar {...props} >
            {isSmall ? (
                <PrzychodFormToolbarMobile {...props} />
            ) : (
                <PrzychodFormToolbarLarge {...props} />
            )}
          </Toolbar>
        );
};

export default PrzychodFormToolbar;
