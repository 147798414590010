import dayjs from 'dayjs';
import {
	GET_LIST,
	GET_ONE,
	GET_MANY,
	GET_MANY_REFERENCE,
	CREATE,
	UPDATE,
	DELETE,
} from 'react-admin';

const provider = (db) => {
	/**
	 * @param {string} type Request type, e.g GET_LIST
	 * @param {string} resource Resource name, e.g. "posts"
	 * @param {Object} payload Request parameters. Depends on the request type
	 * @returns {Promise} the Promise for response
	 */
	return async function (type, resource, params) {
		await db.open();
		const collection = db.table(resource);

		switch (type) {
			case DELETE:
				return await remove(collection, params);
			case GET_ONE:
				return await one(collection, params);
			case CREATE:
				return await add(collection, params);
			case UPDATE:
				return await update(collection, params);
			case GET_LIST:
				return await list(collection, params);
			case GET_MANY:
			case GET_MANY_REFERENCE:
				return await many(collection, params);
			default:
				return null;
		}
	};
};

async function remove(collection, params) {
	const data = await collection.delete(parseInt(params.id));
	return { data };
}

async function one(collection, params) {
	const data = await collection.get(parseInt(params.id));
	return { data };
}

async function add(collection, params) {
	params.data.id = await collection.add(params.data);
	return params;
}

async function update(collection, params) {
	await collection.update(params.data.id, params.data);
	return params;
}

async function list(collection, params) {
	// console.log("provider - col-params:",collection, params);
	
	let query = collection;

	// Sort
	if (params.sort) {
		const { field, order } = params.sort;
		query = query.orderBy(field);
		if (order.toLowerCase() === 'desc') {
			query = query.reverse();
		}
	}

	convertBooleans(params.filter);

	// Filtering
	// napierw funckcja do filtrowania:
	if (Object.keys(params.filter).length > 0) {

		if (params.filter.odwiedziny) {
			// założenie: ograniczenie wyświetlania zapowiedzi do:
			// dwu tygodni wprzód i tych którzy wyszli wczoraj
			let future = dayjs().add(14, 'day').format('YYYY-MM-DD');
			let past = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
			query = query.filter(w => (
				dayjs(w.od).isBefore(dayjs(future)) // zapowiedzi dwa tyg. naprzód
				&& dayjs(w.do).isAfter(dayjs(past)) // wychodzący od wczoraj
				&& ( w.state === 0 || w.state === 1 ) // tylko oczekujące i zaakceptowane
			));
		}

		else if (params.filter.q) {
			// filtrowanie do poprawienia, bo nie działa ;-)
			// założenie: gdy q wtedy przeszukuj wszystkie pola - na kiedyś...
			let filtr = params.filter.q;
			// let fields = query.schema.indexes.map(i=>i.name);
			const filtruj = (f) => {

				let sz = filtr.split(" ");
				delete f.a;
				delete f.d;
				// delete f.id;
				delete f.info;
				let przeszukiwany = Object.values(f).join(" ").toString();
				// console.log("prz",przeszukiwany);
				// console.log("f",f);
				let wynik = sz.map(el=>{
					let szukam = new RegExp(el, "i");
					// console.log("szu",szukam);
					return szukam.test(przeszukiwany)
				});
				// console.log("w",wynik);
				return wynik;
			}

			query = query.filter(f=>filtruj(f));

		} else {
			let keys = Object.keys(params.filter);
			// console.log("klucze wyszukiwania:", keys);
			keys.forEach(key => {
				// console.log("klucz bieżący:", key);

				if (typeof key === 'string') {
					query = query.filter(w => {
						let szukany = new RegExp(params.filter[key], "i");
						return szukany.test(w[key]);
					});
				}

				if (typeof key === 'number') { query = query.filter(w => w[key] === params.filter[key]); }
			});
		}
	}

	// Count
	const count = await query.count();


	// Pagination
	if (params.pagination) {
		const { page, perPage } = params.pagination;
		const offset = page > 1 ? (page - 1) * perPage : 0;
		query = query.offset(offset).limit(perPage);
	}

	const data = await query.toArray(); 

	return {
		data: data,
		total: count,
		page: params.pagination ? params.pagination.page : 1,
		totalCount: count,
	};
}

async function many(collection, params) {
	const count = await collection.count();
	const data = await collection.toArray();
	// console.log("",data);

	return {
		data: data,
		total: count,
		page: 0,
		totalCount: count,
	};
}

function convertBooleans(data) {
	Object.keys(data).forEach((key) => {
		if (typeof data[key] === 'boolean') {
			data[key] = data[key] ? 1 : 0;
		}
	});
}

export default provider;
