import * as React from "react";
import { 
        FormDataConsumer, 
        SimpleForm, 
        Create, 
        Edit, 
        TextInput, 
        NumberInput,
        SelectInput, 
} from 'react-admin';
// import { useFormState } from 'react-final-form';
import { Typography } from "@material-ui/core";

import DatyField from "../Daty";
import { wydatekInit, typy_operacji, wydatekFormat, wydatekZapis } from '../../obliczenia/wydatek';
import { OsobaInputField } from "../osoby/OsobaInputField";
import WydatekFormToolbar from "./WydatekFormToolbar";
// import { NoclegDane } from "../komponenty/NoclegDane";
// import OsobaForm from "./OsobaForm";

// const useStyles = makeStyles({
//     osoba: {
//         width: '90%'
//     },
// });



// const IdentifierField = ({ record }) => (
//         <Typography>{record.id}</Typography>
//     );


const WydatkiForm = props => {

        return (
        <SimpleForm {...props} 
                toolbar={<WydatekFormToolbar />} 
                initialValues={wydatekInit} 
                // validate={validateWydatek} 
                variant="outlined" 
                >
        {/* {console.log("NoclegiForm.props:",props)} */}

        <Typography className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense">
                Wydatek nr: {props.record.id}
        </Typography>

                        <DatyField
                                source="dzien"
                                label="Data operacji"
                        />
                        <TextInput
                                source="tytul"
                                label="Opis"
                                helperText={false}
                                fullWidth
                        />
                        <NumberInput
                                source="kwota"
                                label="Kwota"
                                helperText={false}
                                initialValue={0}
                        />
                        <TextInput
                                source="info.nrfaktury"
                                label="Nr faktury"
                                helperText={false}
                                fullWidth
                        />
                        <SelectInput
                                source="dlug"
                                label="Typ operacji"
                                choices={typy_operacji}
                                optionText="label"
                                optionValue="id"
                                helperText={false}
                        />

                        <OsobaInputField
                                source="wykonawca"
                                label="Wykonawca"
                                reference="osoby"
                                perPage={100000}
                                sort={{ field: 'nazwisko', order: 'ASC' }}
                                fullWidth
                        />

                        <FormDataConsumer fullWidth>{({ formData, ...rest }) =>
                        (<React.Fragment>
                                { formData.dlug === 1 && <OsobaInputField
                                        {...rest}
                                        source="wierzyciel"
                                        label="Wierzyciel"
                                        reference="osoby"
                                        perPage={100000}
                                        sort={{ field: 'nazwisko', order: 'ASC' }}
                                        fullWidth
                                />}
                        </React.Fragment>)}
                        </FormDataConsumer>
                        <TextInput
                                source="info.uwagi"
                                label="Uwagi"
                                rows={4}
                                multiline
                                fullWidth
                        />

        </SimpleForm>
)};

   




export const WydatkiEdit = props => (
    <Edit {...props} transform={(data) => wydatekZapis(wydatekFormat(data))}>
            <WydatkiForm {...props} />
    </Edit>
);

export const WydatkiCreate = props => (
    <Create {...props} transform={(data) => wydatekZapis(wydatekFormat(data))}>
            <WydatkiForm {...props} />
    </Create>
);
