import React from 'react';
import { useRefresh } from 'react-admin';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import SaveIcon from '@material-ui/icons/Save';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import MenuOpenOutlinedIcon from '@material-ui/icons/MenuOpenOutlined';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import Backdrop from '@material-ui/core/Backdrop';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  exampleWrapper: {
    position: 'relative',
    marginTop: theme.spacing(3),
    height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  toolTip: {
          // wordBreak: "keep-all",
          // backgroundColor: "#ccc"
          whiteSpace: 'nowrap',
  },
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}));


export default function PrzychodFormToolbarMobile(props) {

        const classes = useStyles();
        const [open, setOpen] = React.useState(false);
        const history = useHistory();
        const refresh = useRefresh();

        const handleClose = () => {
                setOpen(false);
        };

        const handleOpen = () => {
                setOpen(true);
        };

        // console.log("pris-val",props.pristine,values);

        return (
          <React.Fragment>
            <Backdrop open={open} className={classes.root} />
            <SpeedDial
              ariaLabel="Osoby"
              className={classes.speedDial}
              icon={<SpeedDialIcon
                icon={<MoreVertOutlinedIcon />}
                openIcon={<MoreVertOutlinedIcon style={{ transform: 'rotate(45deg)' }} />}
              />}
              onClose={handleClose}
              onOpen={handleOpen}
              open={open}
            >
              <SpeedDialAction
                // akcja: zapisz dane formularza
                {...props}
                key="zapisz"
                icon={<SaveIcon
                  color="primary"
                />}
                FabProps={{ disabled: props.pristine }}
                tooltipOpen
                classes={{ staticTooltipLabel: classes.toolTip }}
                tooltipTitle="Zapisz"
                onClick={props.handleSubmit}
              />
              <SpeedDialAction
                // resetowanie formularza (wyłączone w odwiedzinach)
                key="dodaj"
                icon={<AddOutlinedIcon color="primary" />}
                tooltipTitle="Dodaj / resetuj"
                onClick={() => {
                  history.push("/przychody/create", { record: {} });
                  refresh();
                }}
                tooltipOpen
                classes={{ staticTooltipLabel: classes.toolTip }}
              />
              <SpeedDialAction
                // tylko w osobach - link do listy osób
                key="lista"
                icon={<MenuOpenOutlinedIcon />}
                FabProps={{ href: "/#/przychody" }}
                tooltipTitle="Wróć do listy przychodów"
                tooltipOpen
                classes={{ staticTooltipLabel: classes.toolTip }}
              />
            </SpeedDial>
          </React.Fragment>
  );
}
