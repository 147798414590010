import * as React from "react";
import { 
        TextInput, 
        FormDataConsumer
} from 'react-admin';
import { Typography } from "@material-ui/core";

import { KeyDatyField } from "../Daty";
import { OsobaSuma } from "./OsobaDane";
import OsobaDuble from "./OsobaDuble";

const OsobaFormFields = props => {

        return (
                <React.Fragment>

                        <Typography className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense">
                                {props.record.id && `Edycja osoby - ID: ${props.record.id}`}
                                {!props.record.id && `Dodawanie nowej osoby`}
                        </Typography><br/>

                  <TextInput
                    source="imie"
                    options={{ autoComplete: "off" }}
                    variant="outlined"
                    helperText={false}
                    label="Imię"
                    fullWidth
                    />
                  <TextInput
                    source="nazwisko"
                    variant="outlined"
                    autoComplete="off"
                    helperText={false}
                    label="Nazwisko"
                    fullWidth
                    />
                  <TextInput
                    source="pesel"
                    variant="outlined"
                    options={{ autoComplete: "off" }}
                    helperText={false}
                    label="PESEL"
                    fullWidth
                    />
                  <FormDataConsumer>
                    {({ formData, ...rest }) => (
                      <>
                        {/* {console.log("formData:",formData)} */}
                        {formData.warn.pesel && (
                          <>
                            <span className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense Mui-error">
                              <i>{formData.warn.pesel}</i>
                            </span>
                            <br />
                            <KeyDatyField
                              {...rest}
                              source="info.dataur"
                              label="Data ur."
                              disableFuture
                              fullWidth
                            />
                            <br />
                            <TextInput
                              {...rest}
                              variant="outlined"
                              source="p"
                              helperText={false}
                              label="Państwo"
                              fullWidth
                            />
                          </>
                        )}
                      </>
                    )}
                  </FormDataConsumer>

                  <TextInput
                    source="info.uwagi"
                    variant="outlined"
                    label="Uwagi"
                    rows={3}
                    multiline
                    fullWidth
                    />

                        <OsobaSuma />
                        { props.record.id && <OsobaDuble {...props} /> }

                </React.Fragment>
        );
}

export default OsobaFormFields;
